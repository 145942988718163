import ServiceApi from "../ServiceApi";

export default class ServiceShare {
    /**
     * Название микросервиса
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';
    /**
     * Маршрут для получения ссылки
     * @static
     * @type {String}
     */
    static routeGetLink = '/shared/get-link';

    /**
     * Маршрут для получения инфо о ссылке
     * @static
     * @type {String}
     */
    static routeGetLinkInfo = '/shared/link-info';
    /**
     * Маршрут для получения ссылки
     * @static
     * @type {String}
     */
    static routeEditLink = '/shared/edit-link';
    /**
     * Маршрут для получения ссылки
     * @static
     * @type {String}
     */
    static routeRemoveLink = '/shared/remove';
    /**
     * Маршрут логина как гость
     * @static
     * @type {String}
     */
    static routeLoginGuest = '/login-guest';

    /**
     * Получить ссылку
     * @static
     * @async
     * @param {Array} kitIds - id набора
     * @param {Object} params - параметры ссылки
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async getLink(kitIds, params, actionAfter = () => {}) {
        try {
            const requestBody = {pg_ids: kitIds, params};
            const response = await ServiceApi.post(this._microserviceName, this.routeGetLink, requestBody);
            const linkObj = this._getLinkBefore(response)
            actionAfter(linkObj);
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить инфо о сссылке
     * @static
     * @async
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async getLinkInfo(actionAfter = () => {}) {
        try {
            const response = await ServiceApi.get(this._microserviceName, this.routeGetLinkInfo);
            const {data: dataLink = {}} = response;
            actionAfter(dataLink);
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Отредактировать ссылку
     * @static
     * @async
     * @param {String} hash
     * @param {Number} activeUntilDays
     * @param {Object} data
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async editLink(hash, activeUntilDays, data, actionAfter = () => {}) {
        try {
            const requestBody = {hash: hash, active_until: activeUntilDays, data};
            const response = await ServiceApi.post(this._microserviceName, this.routeEditLink, requestBody);
            const linkObj = this._getLinkBefore(response)
            actionAfter(linkObj);
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Удалить ссылку
     * @static
     * @async
     * @param {String} hash
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    static async removeLink(hash, actionAfter = () => {}) {
        try {
            await ServiceApi.delete(this._microserviceName, this.routeRemoveLink + "/" + hash);
            actionAfter();
        }
        catch (error) {
            console.log(error);
        }
    }

    /**
     * Формирование ссылки на группу
     * @param {Object} linkParams
     * @return {Object}
     * @private
     */
    static _getLinkBefore(linkParams) {
        const {data: dataLink = {}} = linkParams;
        const {hash = '', active_until: activeUntil = null, created_at: createdAt, data: params = {}} = dataLink;
        let date = activeUntil !== null
            ? "До " + new Date(Number(activeUntil) * 1000).toLocaleString('ru'/*, {year: 'numeric', month: 'long'}*/)
            : "Бессрочно";
        let daysCount = activeUntil === null ? 0 : (activeUntil - createdAt) / (3600* 24);
        const {poi: poiData = {}, competitor: competitorItems = {}, permissions = []} = params;
        const {groups: pois = []} = poiData;
        let poiItems = [];
        pois.forEach((poi) => {
            const {
                icon: poiIcon = null,
                name: poiName = "",
                color: poiColor = "",
                poi_selected_count: poiSelectedCount = null,
                poi_count: poiCount = null
            } = poi;
            poiItems.push({
                icon: poiIcon,
                name: poiName,
                color: poiColor,
                count: poiSelectedCount ?? poiCount ?? 0
            });
        })
        const urlWithoutQuery = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        return {
            activeUntilLabel : date,
            daysCount,
            link: `${urlWithoutQuery}/share?shareToken=${hash}`,
            poiItems,
            competitorItems,
            permissions,
            hash
        };
    }

    /**
     * Конструктор
     * @param {String} shareToken - токен шары
     */
    constructor(shareToken = '') {
        this.shareToken = String(shareToken);
        this.shareProductToken = null;
    }

    /**
     * Логин как гость
     * @param {Function} actionAfter - событие, сработающее после запроса
     */
    async loginGuest(actionAfter = () => {}) {
        try {
            const requestBody = {share_token: this.shareToken};
            const response = await ServiceApi.post(ServiceShare._microserviceName, ServiceShare.routeLoginGuest, requestBody);
            const {data = {}} = response;
            const {product_token: productToken = ''} = data;
            this.setShareProductToken(productToken);
            actionAfter();
        }
        catch (error) {
            const {response = {}} = error;
            const {data: responseData = {}} = response;
            const {data = {}} = responseData;
            const {message = "Ссылка недействительна"} = data;
            alert(message);
        }
    }

    /**
     * Установить токен продукта шары в контекст
     * @param {String} shareProductToken - токен продукта
     */
    setShareProductToken(shareProductToken = '') {
        this.shareProductToken = String(shareProductToken);
    }
}
