import UtilNumbers from "../utils/UtilNumbers";
import UtilDate from "../utils/UtilDate";
import ServiceUser from "../services/ServicesAuth/ServiceUser.js";

/** Менеджер параметров для карточки */
export default class ManagerDraggableCard {

    static _paramsModifier = {
        Budget: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            let {
                price,
                agency_price: agencyPrice = "",
                install_price: installPrice = "",
                print_price: printPrice = "",
                additional_print_price: additionalPrintPrice = ""
            } = activePrice;
            let budget = Number(agencyPrice) + Number(installPrice) + Number(printPrice);
            let discount = Number(price) && Number(agencyPrice) ? (1 - Number(agencyPrice)/Number(price)) : 0;

            return {
                price: Number(price) ? UtilNumbers.toPrice(price) : "",
                budget: Number(budget) ? UtilNumbers.toPrice(budget) : "",
                agencyPrice: Number(agencyPrice) ? UtilNumbers.toPrice(agencyPrice) : "",
                installPrice: Number(installPrice) ? UtilNumbers.toPrice(installPrice) : "",
                printPrice: Number(printPrice) ? UtilNumbers.toPrice(printPrice) : "",
                additionalPrintPrice: Number(additionalPrintPrice) ? UtilNumbers.toPrice(additionalPrintPrice) : "",
                discount: Number(discount) ? `${Math.round(discount * 100)} %` : ''
            };
        },
        Site: (activeSideData) => {
            let {marker = {}} = activeSideData;
            let {lat = "", lng = ""} = marker;
            return {lat, lng};
        },
        Projects: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            return {priceId: String(activeSideData?.activePrice?.id ?? ''), projectList: this._getPriceGroupList("4", activePrice)}
        },
        Description: (activeSideData) => {
            let {city = {}, sideUserInfo = {}, propertyLinks = {}, activePrice = {}, updated_at: sideUpdatedAt = ""} = activeSideData;
            let {updated_at: priceUpdatedAt = ""} = activePrice;
            let lastUpdate = Number(sideUpdatedAt) > Number(priceUpdatedAt)
                ? Number(sideUpdatedAt) : Number(priceUpdatedAt);
            let date = new Date(Number(lastUpdate + "000"));

            let {supp_id:supplier = {}, kind_id: kind = ""} = propertyLinks;
            let {value: supplierValue = ''} = supplier;
            let {value: kindValue = ''} = kind;

            let {
                opn_number:opnNumber = '',
                supp_item_id:gid = '',
                grp = '',
                ots = '',
                move_direction: moveDirection = '',
                realAddress = '',
                image_src: imageSrc = ''
            } = sideUserInfo;

            let {name = ''} = city;
            return {
                supplier: supplierValue,
                kind: kindValue,
                updatedAt: UtilDate.toPrettyString(date.getFullYear(), date.getMonth(), date.getDate()),
                moveDirection,
                ots,
                grp,
                gid,
                opnNumber,
                city: name,
                address: realAddress,
                imageSrc
            };
        },
        Params: (activeSideData) => {
            let {propertyLinks = {}, sideUserInfo = {}, activePrice = {}} = activeSideData;
            let {kind_id:kind = {}, type_id:type = {}, format_id:format = {}} = propertyLinks;
            const {brands = '', projects = '', advertisers = '', industries = ''} = activePrice;
            let {material = ''} = sideUserInfo;
            let {value: typeValue = ''} = type;
            let {value: kindValue = ''} = kind;
            let {value: formatValue = ''} = format;
            return {
                oohdeskId: String(activeSideData?.catalogData?.oohdesk_id ?? ''),
                city: String(activeSideData?.city?.name ?? ''),
                suppId: String(activeSideData?.propertyLinks?.supp_id?.value ?? ''),
                gid: String(activeSideData?.sideUserInfo?.supp_item_id ?? ''),
                type: typeValue,
                kind: kindValue,
                coords: [String(activeSideData?.marker?.lat ?? ''), String(activeSideData?.marker?.lng ?? '')].join(', '),
                format: formatValue,
                grp: String(activeSideData?.sideUserInfo?.grp ?? ''),
                ots: String(activeSideData?.sideUserInfo?.ots ?? ''),
                sideName: String(activeSideData?.side_name ?? ''),
                moveDirection: String(activeSideData?.sideUserInfo?.move_direction ?? ''),
                material,
                lighted: String(activeSideData?.propertyLinks?.lighted?.value ?? ''),
                opn: String(activeSideData?.sideUserInfo?.opn_number ?? ''),
                printPrice: UtilNumbers.toPrice(activeSideData?.sideUserInfo?.print_price),
                installPrice: UtilNumbers.toPrice(activeSideData?.sideUserInfo?.install_price),
                brands, projects, advertisers, industries
            }
        },
        Comment: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            let {comment = ""} = activePrice;
            return {comment};
        },
        Maket: (activeSideData) => {
            let {activePrice = {}} = activeSideData;
            return {priceId: String(activeSideData?.activePrice?.id ?? ''), layoutList: this._getPriceGroupList("3", activePrice)}
        },
        Photo: (activeSideData) => {
            const photo = activeSideData?.supplierPhoto ?? (activeSideData?.catalogData?.photo ?? {});

            let {middle = "", big = ''} = photo;
            return {photoMiddle: middle, photoBig: big};
        },
        Status: (activeSideData = {}) => {
            return this._getStatusData(activeSideData, 'statuses', 'status');
        },
        StatusUser: (activeSideData = {}) => {
            return this._getStatusData(activeSideData, 'statusesUser', 'user_status');
        }
    }

    static _getStatusData(activeSideData = {}, statusesKey = '', statusKey = '') {
        const isShowed = activeSideData?.activePrice?.priceGroups?.filter(priceGroup => String(priceGroup?.type ?? '') === '1')?.length === 0;
        const statusPermissions = {
            'status': 'agency',
            'user_status': 'client'
        };
        const userPermissions = ServiceUser.profile?.extraData?.permissions ?? {};
        const isDisabled = userPermissions[statusPermissions[statusKey] ?? ''] ? false : true;

        const statusList = (activeSideData[statusesKey] ?? []).map(status => ({...status, value: String(status?.label ?? '')}));
        const statusSelectedId = String(activeSideData?.activePrice[statusKey] ?? '');
        const statusSelected = statusList.find(status => String(status?.id ?? '') === statusSelectedId) ?? {};
        const statusSelectedColor = `#${statusSelected?.color ?? ''}`;

        const priceId = String(activeSideData?.activePrice?.id ?? '');

        return { isShowed, isDisabled, statusList, statusSelectedId, statusSelectedColor, priceId, attribute: statusKey };
    }

    static getParams(componentName, activeSideData){
        const loader = this._paramsModifier[componentName] ?? function () {};
        let data = loader(activeSideData);
        return data;
    }

    /**
     * Список макетов или проектов
     * @param {String} type
     * @param {Object} activePrice
     * @returns {[]}
     */
    static _getPriceGroupList(type, activePrice){
        let {priceGroups = []} = activePrice;
        let list = [];
        priceGroups.forEach((item) => {
            let {id, name, type: groupType} = item;
            if(groupType === type){
                list.push({id, name});
            }
        });
        return list;
    }

}
