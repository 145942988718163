<template>
    <div class="summary-box">
        <AdMetrixItem
            v-for="(calculation, index) in calculations"
            :key="index"
            :result="calculation"
            @onClose="onCloseItem"
            @onClickCalc="onClickCalc"
            @export="onExport"
        />
    </div>
</template>

<script>
    import AdMetrixItem from "./AdMetrixItem";
    import ServiceEspar from "../../services/ServicesEspar/ServicesEspar";
    import {mapState} from "vuex";
    export default {
        name: "AdMetrixWidget",
        components: {
            AdMetrixItem
        },
        props: {
            admetrix: {
                type: Array,
                default: () => [],
            }
        },
        /**
         *
         * @returns {Array<Object>} calculations
         */
        data: () => ({
            calculations: []
        }),
        computed:{
            /**
             * Проксируем состояния из стора
             * @returns {Object} state - состояния
             * @returns {Object} state.filterSelectedData - выбранные данные фильтра
             * @returns {Array<Number|String>} state.filterSelectedData.city_id - выбранные идентификаторы городов фильтра
             * @returns {Array<String>} state.filterSelectedData.date - выбранные даты фильтра
             * @returns {Array<Object>} state.selectedSides - выбранные стороны
             */
            ...mapState({
                filterSelectedData: state => state.filter.filterSelectedData,
                selectedSides: state => state.selection.selectedSides
            }),
        },
        beforeMount() {
            this.updateEsparItems();
        },
        watch: {
            admetrix(){
                this.updateEsparItems();
            },
        },
        methods:{
            onClickCalc(calculationId, days){
                const calcIndex = this.admetrix.findIndex(toCalc => String(toCalc.id) === String(calculationId))
                if(calcIndex === -1){
                    return false;
                }
                ServiceEspar.postEspar(this.admetrix[calcIndex], days, (result) => {
                    result.count === this.admetrix[calcIndex] === "selected" ? this.selectedSides.length : 0;
                    let index = this.calculations.findIndex(calculation => String(calculation.id) === String(calculationId));
                    this.calculations.splice(index, 1, result);
                }, (errorText) => {
                    let index = this.calculations.findIndex(calculation => String(calculation.id) === String(calculationId));
                    let tmpCalculation = {...this.calculations[index]};
                    tmpCalculation.params = {};
                    tmpCalculation.error = errorText;
                    this.calculations.splice(index, 1, tmpCalculation);
                });
            },
            updateEsparItems(){
                let calculations = [];
                this.admetrix.forEach((kitToAdmetrix) => {
                    const {id, name, type} = kitToAdmetrix;
                    const calculationIndex = this.calculations.findIndex(calculation => calculation.id === id);
                    if(calculationIndex === -1){
                        calculations.push({
                            id,
                            name,
                            count: type === "selected" ? this.selectedSides.length : 0,
                            params:{}
                        });
                    }else{
                        calculations.push(this.calculations[calculationIndex]);
                    }
                });
                this.calculations = calculations;
            },
            onCloseItem(calculationId){
                this.$emit("onCloseAdmetrix", calculationId);
            },
            onExport(exportKey = '') {
                this.export(exportKey);
            },
            async export(exportKey = '') {
                try {
                    const fileBlobData = await ServiceEspar.getEsparExport(exportKey);
                    const fileUrl = window.URL.createObjectURL(fileBlobData);
                    
                    window.open(fileUrl, '_blank');
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-box{
        display: inline-flex;
        float: left;
        /*bottom: 0;*/
        /*position: absolute;*/
    }
</style>
