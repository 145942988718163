import UtilLocalStorage from '../../utils/UtilLocalStorage';
export default class ServiceMapLegends {
    static _localStoragePositionLegendsKey = 'positionLegends';

    static _localStorageGroupSelectedLegendsKey = 'groupSelectedLegends';

    static _localStorageControlPanelBlocksKeysShowedKey = 'controlPanelBlocksKeysShowed';

    static _localStorageLegendPanelKeysShowed = 'legendPanelKeysShowed';

    static get _isPositionLegends() {
        return UtilLocalStorage.isData(this._localStoragePositionLegendsKey);
    }

    static get _isGroupSelectedLegends() {
        return UtilLocalStorage.isData(this._localStorageGroupSelectedLegendsKey);
    }

    static get _isControlPanelBlocksKeysShowed() {
        return UtilLocalStorage.isData(this._localStorageControlPanelBlocksKeysShowedKey);
    }

    static get _isLegendPanelKeysShowed() {
        return UtilLocalStorage.isData(this._localStorageLegendPanelKeysShowed);
    }

    static get _positionLegendsDefault() {
        return {
            controlPanel: {
                top: '10px',
                left: `${window.innerWidth - 440}px`
            },
            placement: {
                top: '10px',
                left: `${window.innerWidth - 600}px`
            },
            offer: {
                top: '10px',
                left: `${window.innerWidth - 760}px`
            },
            colorPanel: {
                top: '10px',
                left: `${window.innerWidth - 980}px`
            },
            heatmapPanel: {
                top: '10px',
                left: `${window.innerWidth - 1140}px`
            }
        };
    }

    static get _positionLegendsDefaultMobile() {
        return {
            controlPanel: {
                // top: `${window.innerHeight - 455}px`,
                bottom: '65px',
                left: '10px'
            },
            placement: {
                top: '10px',
                left: '65px'
            },
            offer: {
                top: '10px',
                left: '180px'
            },
            colorPanel: {
                // top: `${window.innerHeight - 495}px`,
                left: '105px',
                bottom: '65px'
            },
            heatmapPanel: {
                left: '105px',
                bottom: '65px'
            }
        };
    }

    static _groupSelectedLegendsDefault = {
        placement: 'default',
        offer: 'default'
    };

    static _controlPanelBlocksKeysShowedDefault = ['mode', 'markers', 'traffic', 'tool'];

    static _controlPanelBlocksKeysShowedDefaultMobile = ['mode'];

    static _legendPanelKeysShowedDefault = ['offer', 'placement', 'heatmap'];

    static getPositionLegends(isMobile = false) {
        if (isMobile)
            return this._positionLegendsDefaultMobile;
        else
            return this._isPositionLegends ? UtilLocalStorage.getData(this._localStoragePositionLegendsKey) : this._positionLegendsDefault;
    }

    static setPositionLegends(positionLegends = {}) {
        UtilLocalStorage.setData(this._localStoragePositionLegendsKey, positionLegends);
    }

    static setPositionLegendsDefault() {
        UtilLocalStorage.setData(this._localStoragePositionLegendsKey, this._positionLegendsDefault);
    }

    static getGroupSelectedLegends() {
        return this._isGroupSelectedLegends ? UtilLocalStorage.getData(this._localStorageGroupSelectedLegendsKey) : this._groupSelectedLegendsDefault;
    }

    static setGroupSelectedLegends(groupSelectedLegends = {}) {
        UtilLocalStorage.setData(this._localStorageGroupSelectedLegendsKey, groupSelectedLegends);
    }

    static getControlPanelBlocksKeysShowed(isMobile = false) {
        if (isMobile)
            return this._controlPanelBlocksKeysShowedDefaultMobile;
        else
            return this._isControlPanelBlocksKeysShowed ? UtilLocalStorage.getData(this._localStorageControlPanelBlocksKeysShowedKey) : this._controlPanelBlocksKeysShowedDefault;
    }

    static setControlPanelBlocksKeysShowed(controlPanelBlocksKeysShowed = []) {
        UtilLocalStorage.setData(this._localStorageControlPanelBlocksKeysShowedKey, controlPanelBlocksKeysShowed);
    }

    static getLegendPanelKeysShowed() {
        return this._isLegendPanelKeysShowed ? UtilLocalStorage.getData(this._localStorageLegendPanelKeysShowed) : this._legendPanelKeysShowedDefault;
    }

    static async setLegendPanelKeysShowed(legendPanelKeysShowed = []) {
        UtilLocalStorage.setData(this._localStorageLegendPanelKeysShowed, legendPanelKeysShowed);
    }
}