<template>
    <div class="mobile-dropdown-menu">
        <div class="mobile-dropdown-menu__preview">
            <BaseButtonIcon
                :isDisabled="isDisabled"
                :isActive="isShowContent"
                tooltipText="Действия над выбранным"
                iconComponent="IconHamburger"
                @onBaseButtonIcon="toggleShowContent"
            />
        </div>
        <div
            v-if="isShowContent" 
            class="mobile-dropdown-menu__backdrop"
            @click.self="onBackdrop"
        >
        </div>
        <transition name="rolling">
            <div
                v-if="isShowContent" 
                class="mobile-dropdown-menu__content"
            >
                <div 
                    class="mobile-dropdown-menu__header"
                    @mousedown="onMouseDownHeader"
                    @mouseup="onMouseUpHeader"
                    @mousemove="onMouseMoveHeader"
                >
                    <div class="mobile-dropdown-menu__header-line" />
                </div>
                <div
                    ref="mobileDropdownMenuItems" 
                    class="mobile-dropdown-menu__items"
                >
                    <BaseButton 
                        v-if="actionCurrentId !== 'root'"
                        class="mobile-dropdown-menu__back"
                        @click="onBack"
                    >
                        <IconArrowV2 class="mobile-dropdown-menu__back-icon"/>
                        Назад
                    </BaseButton>
                    <MobileDropdownItem
                        v-for="(action, index) in actionsShowed"
                        :key="index"
                        :action="action"
                        @onClickAction="onClickAction"
                        @onClickActionMulti="onClickActionMulti"
                    />
                </div>   
            </div>
        </transition>
    </div>
</template>

<script>
import BaseButton from '../../Base/BaseButton.vue';
import BaseButtonIcon from '../../Base/BaseButtonIcon.vue';
import MobileDropdownItem from './MobileDropdownItem.vue';
import IconArrowV2 from '../../Icons/IconArrowV2.vue';
export default {
    name: 'MobileDropdownMenu',
    components: {
        BaseButton,
        BaseButtonIcon,
        MobileDropdownItem,
        IconArrowV2
    },
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        isShowContent: false,
        isMouseDownHeader: false,
        mouseDownClientY: null,
        actionCurrentId: 'root'
    }),
    computed: {
        actionsShowed() {
            return this.actionCurrentId === 'root' ? this.actions : this.actions.find(action => String(action?.id) === String(this.actionCurrentId))?.items;
        }
    },
    methods: {
        toggleShowContent() {
            this.isShowContent = !this.isShowContent;
            this.actionCurrentId = 'root'
        },
        
        onMouseDownHeader(event) {
            const {clientY} = event;
            this.mouseDownClientY = clientY;
            this.isMouseDownHeader = true;
        },

        onMouseUpHeader() {
            this.mouseDownClientY = null;
            this.isMouseDownHeader = false;
        },

        onMouseMoveHeader(event) {
            if(this.isMouseDownHeader && event.clientY - this.mouseDownClientY > 20) {
                this.onMouseUpHeader();
                this.toggleShowContent();
            }
                
        },

        onClickAction(action = '', actionEmitName = '') {
            if (actionEmitName !== '')
                this.$emit(actionEmitName, action);
            else
                this.$emit('onClickAction', action);

            this.$emit('onClickActionNative', action);
            
            this.toggleShowContent();
        },

        onClickActionMulti(action = '') {
            const {id = ''} = action;
            this.actionCurrentId = String(id);
        },

        onBack() {
            this.actionCurrentId = 'root';
        },

        onBackdrop() {
            this.toggleShowContent();
        }
    }
}
</script>

<style lang="scss" scoped>
    .rolling-enter, .rolling-leave-to {
        transform: translateY(100%);
    }

    .rolling-enter-active, .rolling-leave-active {
        transition: all 0.5s;
    }

    .mobile-dropdown-menu {
        &__preview {
            cursor: pointer;
        }

        &__backdrop {
            position: fixed;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 7;
            background: rgba(0, 0, 0, 0.8);
        }

        &__content {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 8;
            width: 100%;
            // height: 70%;
            background: #fff;
            border-radius: 20px 20px 0px 0px;
        }

        &__header {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: grab;

            &-line {
                width: 36px;
                height: 0px;
                border: 2px solid #000000;
            }
        }

        &__items {
            padding: 0 40px 30px 40px;
            height: 100%;
        }

        &__back {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            user-select: none;
            background: #F4F5F5;
            border-radius: 10px;
            color: #4A91F1;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;

            &-icon {
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
                margin-right: 10px;

                ::v-deep {
                    path{
                        fill: #4A91F1;
                    }
                }
            }
        }
    }
</style>