<template>
    <div class="summary-item">
        <button class="summary-item__btn-close icon-tabler-icon-x" @click="onCloseItem"></button>
        <div class="summary-item__title">{{name}}</div>
        <div 
            v-if="count"
            class="summary-item__title"
        >
            {{count}} ст
        </div>
        <!-- <div class="input-number-custum">
            <a
                :disabled="countDays <= 1"
                class="input-number-custum-button input-number-button-minus"
                @click="countDays <= 1 ? 1 : countDays--"
            >-</a>
            <input type="number" v-model="countDays" min="1" max="90" style="appearance: textfield;">
            <a
                class="input-number-custum-button input-number-button-plus"
                @click="countDays >= 90 ? 90 : countDays++"
            >+</a>
        </div> -->

        <div class="input-number-custum">
            <div
                :disabled="Number(countDays) <= 1"
                class="input-number-custum-button input-number-button-minus"
                @click="countDays <= 1 ? 1 : countDays--"
            >
                <IconMinus class="input-number-custum-button__icon"/>
            </div>
            <input
                type="number"
                v-model="countDays"
                min="1"
                max="90"
                style="appearance: textfield;"
            >
            <div
                class="input-number-custum-button input-number-button-plus"
                @click="countDays >= 90 ? 90 : countDays++"
            >
                <IconPlus class="input-number-custum-button__icon"/>
            </div>
        </div>

        <div v-if="error">
            {{error}}
        </div>

        <AdMetrixItemParams
            v-if="params.length"
            :params="params"
        />

        <div 
            :class="[
                'button-container',
                {'button-container_after-calculating': isExport }
            ]"
        >
            <BaseButton
                v-if="isExport && !isLoading" 
                :view="'simple'"
                class="button-container__button-export"
                @click="onExport"
            >
                Скачать
            </BaseButton>
            
            <BaseButton @click="onClickCalc">
                <span v-if="!isLoading">
                    Рассчитать
                </span>
                <BasePreloader
                    v-else
                    :size="20"
                />
            </BaseButton>
        </div>

    </div>
</template>

<script>
    import AdMetrixItemParams from "./AdMetrixItemParams";
    import BaseButton from "../Base/BaseButton";
    import BasePreloader from "../Base/BasePreloader";
    import IconMinus from '../Icons/IconMinus.vue';
    import IconPlus from '../../components/Icons/IconPlus.vue';
    export default {
        name: "AdMetrix",
        components: {
            BaseButton,
            AdMetrixItemParams,
            BasePreloader,
            IconMinus,
            IconPlus
        },
        data: () => ({
            countDays: 30,
            isLoading: false
         }),
        /**
         * @return {Object} summary сводные данные для группы или выбранного
         */
        props: {
            result: {
                type: Object,
                default: () => {},
            }
        },
        watch:{
            result(){
                this.isLoading = false;
            },
            countDays(){
                if(this.countDays > 90){
                    this.countDays = 90;
                }
                if(this.countDays < 1){
                    this.countDays = 1;
                }
            }
        },
        computed:{
            name(){
                const {name = ""} = this.result;
                return name;
            },
            params(){
                const {params = []} = this.result;
                return params;
            },
            count(){
                const {count = 0} = this.result;
                return count;
            },
            error(){
                const {error = ""} = this.result;
                return error;
            },
            exportKey(){
                return String(this.result?.exportKey ?? '');
            },
            isExport() {
                return this.params.length > 0 && this.error === '';
            }
        },
        methods:{
            onCloseItem(){
                const {id} = this.result
                this.$emit('onClose', id);
            },
            onClickCalc(){
                if(!this.isLoading){
                    const {id} = this.result;
                    this.isLoading = true;
                    this.$emit("onClickCalc", id, this.countDays);
                }
            },
            onExport() {
                this.$emit('export', this.exportKey);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .summary-item{
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        position: relative;
        width: 210px;
        margin: 5px;
        overflow-y: auto;
        max-height: 326px;
        box-shadow: 2px 3px 20px rgba(59,74,116,.10196078431372549);

        &__title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            white-space: nowrap;
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__description{
            color: #AAB0B8;
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        &__container{
            box-sizing: border-box;
            margin-top: 8px;
            background: #FFFFFF;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 4px;
        }

        &__container-head{
            display: flex;
            padding: 8px;
        }

        &__container-body{
            background: #F4F5F5;
            /* gray light */
            border: 1px solid #F4F5F5;
            border-radius: 0 0 4px 4px;
            padding: 8px 8px 4px 8px;
        }

        &__container-title{
            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
        }

        &__container-value{
            /* fonts small */

            // font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            /* identical to box height, or 120% */
            text-align: right;
            /* black */
            color: #000000;

            margin-left: auto;
        }

        &__btn-close {
            position: absolute;
            top: 16px;
            right: 16px;
            margin: 0;
            padding: 0;
            font-size: 12px;
            color: #AFB0B4;
            z-index: 12;
            transition: all .4s ease-out;

            &:hover, &:active {
                color: #000;

            }
        }
    }

    .input-number-custum {
        // display: flex;
        // justify-content: center;
        // margin-top: 8px;
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }

    .button-container {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        &_after-calculating {
            justify-content: end;
        }

        &__button-export {
            margin-right: 8px;
        }
    }

    .input-number-button-minus {
        border-right: 0;
        border-radius: 4px 0 0 4px;
    }

    .input-number-custum input[type="number"] {
        // width: 34px;
        // height: 34px;
        // border: 1px solid #dcdfe5;
        // display: inline-block;
        // text-align: center;

        width: 36px;
        height: 36px;
        border: 1px solid #dcdfe5;
        border-radius: 10px;
        display: inline-block;
        text-align: center;
        margin-right: 4px;

        &:active, &:hover, &:focus {
            outline: 0;
            outline-offset: 0;
        }

        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .input-number-button-plus {
        border-left: 0;
        border-radius: 0 4px 4px 0;
    }

    .input-number-custum-button {
        // background: #fff;
        // font-size: 14px;
        // font-weight: bold;
        // width: 24px;
        // height: 34px;
        // text-align: center;
        // line-height: 34px;
        // display: inline-block;
        // border: 1px solid #dcdfe5;
        // border-left-color: rgb(220, 223, 229);
        // border-left-style: solid;
        // border-left-width: 1px;
        // cursor: pointer;
        // outline: 0;
        // -webkit-transition: all 250ms;
        // -moz-transition: all 250ms;
        // transition: all 250ms;

        background: #fff;
        font-size: 14px;
        font-weight: bold;
        // width: 24px;
        // height: 34px;
        text-align: center;
        line-height: 34px;
        // display: inline-block;
        border: 1px solid #dcdfe5;
        border-left-color: rgb(220, 223, 229);
        border-left-style: solid;
        border-left-width: 1px;
        cursor: pointer;
        outline: 0;
        -webkit-transition: all 250ms;
        -moz-transition: all 250ms;
        transition: all 250ms;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 10px;
        padding: 0;
        margin-right: 4px;

        &__icon {
            width: 18px;
            height: 18px;
        }
    }
</style>
