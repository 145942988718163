<template>
    <div :class="kitItemModifierClass" class="kit-panel-item">
        <div class="kit-panel-item__row kit-panel-item__header">
            <div
                v-if="!isRenameMode"
                :class="[
                    'kit-panel-item__name',
                    { kitItemNameClass, kitItemIsActive },
                ]"
                @dblclick="onToggleMode(true)"
                @mousedown.stop
            >
                <BasePreloaderSkeleton v-if="isLoading" />
                {{ kitItemName }}
            </div>
            <BaseTextInput
                v-else
                v-model="kitItemNameNew"
                ref="baseTextInput"
                @onBlur="onRename"
            />
            <div
                v-if="kitItemIsCanEndEdit && isRealUser && !isShare"
                class="kit-panel-item__end-edit"
                @click="onClickAction('onEndEdit', 'endEdit')"
            >
                <div
                    v-if="isKitItemFinishedAt"
                    class="kit-panel-item__label-checked"
                >
                    <IconArrowV2 class="kit-panel-item__label-checked-icon"/>
                </div>
                <IconLock class="kit-panel-item__end-edit-icon"/>
            </div>
        </div>
        <div class="kit-panel-item__row">
            <div class="kit-panel-item__count">
                <BasePreloaderSkeleton v-if="isLoading" />
                {{ kitItemCount }}
            </div>
            <div class="kit-panel-item__sum">
                <BasePreloaderSkeleton v-if="isLoading" />
                {{ kitItemSum }}
            </div>
        </div>
        <div class="kit-panel-item__row">
            <KitPanelActionList
                :kitActions="kitActionsShowed"
                :kitActionsActive="kitItemActionsActive"
                :kitColor="kitItemColor"
                :kitIsActive="kitItemIsActive"
                :kitIsShared="kitItemIsShared"
                :isLoading="isLoading"
                ref="kitPanelActionList"
                @onClickAction="onClickAction"
            />
        </div>
    </div>
</template>

<script>
/** Компонент набора (размещение, предложение, макет, проект и  т.д.) */
import KitPanelActionList from "./KitPanelActionList.vue";
import BasePreloaderSkeleton from "../Base/BasePreloaderSkeleton.vue";
import BaseTextInput from "../Base/BaseTextInput.vue";
import IconLock from '../Icons/IconLock.vue';
import IconArrowV2 from '../Icons/IconArrowV2.vue';
import UtilNumbers from "../../utils/UtilNumbers";
export default {
    name: "KitPanelItem",
    components: {
        KitPanelActionList,
        BasePreloaderSkeleton,
        BaseTextInput,
        IconLock,
        IconArrowV2
    },
    /**
     * Входные данные компонента
     * @property {Object} kitItem - набор
     * @property {String} kitItem.id - идентификатор набора
     * @property {String} kitItem.name - название набора
     * @property {String|Number} kitItem.count - колличество сторон набора
     * @property {String|Number} kitItem.sum - сумма цен набора
     * @property {String} kitItem.color - цвет набора
     * @property {Array<String>} kitItem.actionsActive - идентификаторы активных действий набора
     * @property {Array<Object>} kitActions - действия для наборов
     * @property {String} kitActions[].id - идентификатор действия для наборов
     * @property {String} kitActions[].icon - иконка действия для наборов
     * @property {String} kitActions[].emitName - название эмита действия для наборов
     * @property {Boolean} isLoading - условие загрузки
     */
    props: {
        kitItem: {
            type: Object,
            default: () => ({}),
        },
        kitActions: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isRealUser: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        isRenameMode: false,
        kitItemNameNew: "",
        isRenameEmit: true,
        kitItemModifier: "narrow",
    }),
    computed: {
        /**
         * Идентификатор набора
         * @returns {String}
         */
        kitItemId() {
            return String(this.getKitItemProp("id") ?? "");
        },

        kitItemType() {
            return String(this.getKitItemProp("type") ?? "");
        },

        /**
         * Название набора
         * @returns {String}
         */
        kitItemName() {
            return String(this.getKitItemProp("name") ?? "");
        },

        /**
         * Колличество сторон набора
         * @returns {String}
         */
        kitItemCount() {
            return UtilNumbers.toCount(
                String(this.getKitItemProp("count") ?? "")
            );
        },

        /**
         * Сумма цен набора
         * @returns {String}
         */
        kitItemSum() {
            return UtilNumbers.toPrice(
                String(this.getKitItemProp("sum") ?? "")
            );
        },

        /**
         * Цвет набора
         * @returns {String}
         */
        kitItemColor() {
            return String(this.getKitItemProp("color") ?? "");
        },

        /**
         * Идентификаторы активных действий набора
         * @returns {Array<String>}
         */
        kitItemActionsActive() {
            return this.getKitItemProp("actionsActive") ?? [];
        },

        /**
         * Активен ли набора
         * @return {Boolean}
         */
        kitItemIsActive() {
            return this.kitItemActionsActive.length > 0;
        },

        /**
         * Класс для набора
         * @return {String}
         */
        kitItemNameClass() {
            return this.kitItemIsActive ? "" : "kit-panel-item__name_disabled";
        },

        kitItemModifierClass() {
            return `kit-panel-item_${this.kitItemModifier}`;
        },

        kitIsGeneral() {
            return Boolean(this.getKitItemProp("isGeneral") ?? false);
        },

        kitIsRenameMode() {
            return !this.kitIsGeneral;
        },

        kitItemIsShared() {
            return Boolean(this.getKitItemProp("isShared") ?? false);
        },

        kitItemIsCanEndEdit() {
            return Boolean(this.getKitItemProp("isCanEndEdit") ?? false);
        },

        isKitItemFinishedAt() {
            return this.getKitItemProp('finishedAt') !== null;
        },

        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },

        kitActionsShowed() {
            const kitActionsShowed = this.kitActions.filter(kitAction => {
                const {isShare = true} = kitAction;
                const kitTypes = kitAction?.kitTypes ?? [];
                const isValidKitType = !kitTypes.length || kitTypes.includes(String(this.kitItemType));
                const isValidShare = !this.isShare || isShare;
                return isValidKitType && isValidShare;
            });

            return kitActionsShowed;
        }
    },
    watch: {
        kitItemName() {
            this.kitItemNameNew = this.kitItemName;
        },
    },
    mounted() {
        this.kitItemNameNew = this.kitItemName;
    },
    methods: {
        /**
         * Получить свойство объекта набора
         * @param {String} propKey - ключ объекта набора
         * @returns {String|Number|Object}
         */
        getKitItemProp(propKey = "") {
            return this.kitItem[propKey] ?? null;
        },

        /**
         * Событие клика по кнопке действия набора
         * @param {String} kitActionEmitName - название эмита действия
         * @param {String} kitActionId - идентификатор действия
         */
        onClickAction(kitActionEmitName = "", kitActionId = "") {
            this.$emit("onClickAction", kitActionEmitName, kitActionId, this.kitItemId);

            if (kitActionId === "more" && this.kitItemActionsActive.includes("more"))
                this.kitItemModifier = "wide";
            else if (kitActionId === "more" && !this.kitItemActionsActive.includes("more"))
                this.kitItemModifier = "narrow";
        },

        onToggleMode(isRenameMode = false) {
            if (this.kitIsRenameMode) this.isRenameMode = Boolean(isRenameMode);

            if (this.isRenameMode)
                this.$nextTick(() => this.$refs.baseTextInput.onFocus());
        },

        onRename() {
            this.onToggleMode(false);
            if (this.isRenameEmit && this.kitItemName !== this.kitItemNameNew) {
                this.isRenameEmit = false;
                this.$emit("onRename", this.kitItemNameNew, this.kitItemId);
                this.$nextTick(() => (this.isRenameEmit = true));
            }
        },

        closeMenuContext() {
            this.$refs.kitPanelActionList.closeMenuContext();
        },
    },
};
</script>

<style lang="scss" scoped>
.kit-panel.column .kit-panel-item {
    width: 100%;
}
.kit-panel.row .kit-panel-item {
    &:first-child {
        background: #ffffff;
        border: 1px solid #dddfe4;
        // width: 220px;
    }
}
.kit-panel-item {
    overflow: hidden;
    height: 80px;
    padding: 10px;
    background: $primary-color;
    border-radius: 10px;
    flex-shrink: 0;
    transition: width ease-in-out 0.3s;

    // &_wide {
        width: 245px;
    //     // transition: width .3s;
    // }

    // &_narrow {
    //     width: 189px;
    //     // transition: width .5s;
    //     // transition-delay: .5s;
    // }

    &__row:not(:last-child) {
        display: flex;
        margin-bottom: 5px;
    }

    &__name {
        max-width: 160px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
        position: relative;

        &_disabled {
            color: $assistant-color;
        }

        &.kitItemIsActive {
            overflow: inherit;
        }
    }

    @mixin sub-data {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: $assistant-color;
        position: relative;
    }

    &__count {
        margin-right: 10px;

        @include sub-data;
    }

    &__sum {
        @include sub-data;
    }

    &__end-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        transition: background-color .6s ease-out;
        margin-right: 8px;

        &-icon {
            width: 12px;
            height: 12px;

            ::v-deep path {
                fill: #aab0b8;
            }

            &:hover {
                ::v-deep path {
                    fill: #000;
                }
            }
        }
    }

    &__header {
        justify-content: space-between;
    }

    &__label-checked {
        display: flex;
        position: absolute;
        top: -2px;
        right: -2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #6ec87a;

        &-icon {
            width: 8px;
            height: 8px;

            ::v-deep path {
                fill: white;
            }
        }
    }
}
</style>
