<template>
    <div
        :class="{'construction-info_mobile': isMobile}"
        class="construction-info"
    >
        <div class="construction-info__wrapper">
            <button
                :class="{'construction-info__close_mobile': isMobile}"
                class="construction-info__close icon-tabler-icon-x" 
                @click="$emit('close')"
            >
            </button>
            <div class="construction-info__header">
                <div class="construction-info__header-nav">
                    <div class="construction-info__header-nav-calendar">
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <BaseDropdown
                            class="construction-info__date-wrapper"
                            position="left"
                            ref="datepicker"
                            :padding="0"
                            @changeVisible="onChangeVisibleDatepicker"
                        >
                            <template #preview>
                                <BaseButton
                                    class="construction-info__date-btn"
                                    view="border"
                                    :active="isDatepickerOpen"
                                >
                                    <!-- <span class="construction-info__date-icon icon-tabler-icon-calendar-minus"></span> -->
                                    <IconCalendar class="construction-info__icon-component" />
                                    <span class="construction-info__date-title">{{dateTitle}}</span>
                                    <IconArrowV2 class="construction-info__arrow"/>
                                </BaseButton>
                            </template>

                            <template #content>
                                <DatePicker
                                    :isMultiple="false"
                                    :accessDates="availableDates"
                                    :checkedDates="activePriceDPDates"
                                    @cancel="$emit('$closeDropdown')"
                                    @approve="onApproveDatepicker"
                                />
                            </template>
                        </BaseDropdown>
                    </div>
                    <div class="construction-info__header-nav-sides">
                        <template v-if="isLoading">
                            <button
                                v-for="(item, index) in sideListShadow"
                                :key="index"
                                :id="index"
                                class="construction-info__header-button"
                            >
                                <BasePreloaderSkeleton v-if="isLoading" />
                                {{ item.code }}
                            </button>
                        </template>
                        <template v-if="!isLoading">
                            <button
                                v-for="(item, index) in sideList"
                                :key="index"
                                :id="index"
                                :class="{'construction-info__header-button--active' : activeSideId === item.id}"
                                class="construction-info__header-button"
                                @click="toggleSide(item)"
                            >
                                {{ item.code }}
                            </button>
                        </template>
                    </div>
                </div>

                <div class="construction-info__header-actions" v-if="false">
                    <BaseButtonIcon
                        v-for="(item, index) in headerActions"
                        :key="item.index"
                        :id="'action-button-' + index"
                        :tooltip-text="item.tooltip"
                        :icon-name="item.icon"
                    />

                    <BaseDropdownMenu :options="headerMenuItems"/>
                </div>
            </div>
            <div class="construction-info__content">
                <Notification
                    v-if="isSelectedPrice"
                    class="construction-info__content-notification"
                    type="success"
                    text="Сторона выбрана"
                />

                <div
                    v-if="getComponentDataStatusIsShowed('Status')"
                    :class="{ 'construction-info__status_mobile': isMobile }"
                    class="construction-info__status"
                >
                    <div
                        :class="{ 'construction-info__status-item_mobile': isMobile }"
                        class="construction-info__status-item"
                    >
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <div class="construction-info__status-item-title"> Статус агентства </div>
                        <BaseSelect
                            :isBorder="true"
                            :isDisabled="getComponentDataStatusIsDisabled('Status')"
                            :options="getComponentDataStatusList('Status')"
                            :value="getComponentDataStatusSelectedId('Status')"
                            :defaultTitle="'Статус агентства'"
                            :tagColor="getComponentDataStatusSelectedColor('Status')"
                            :style="{'max-width': '190px', 'width': '190px'}"
                            @check="onSelectStatus($event, 'Status')"
                        />
                    </div>

                    <div class="construction-info__status-item">
                        <BasePreloaderSkeleton v-if="isLoading" />
                        <div class="construction-info__status-item-title"> Статус клиента </div>
                        <BaseSelect
                            :isBorder="true"
                            :isDisabled="getComponentDataStatusIsDisabled('StatusUser')"
                            :options="getComponentDataStatusList('StatusUser')"
                            :value="getComponentDataStatusSelectedId('StatusUser')"
                            :defaultTitle="'Статус клиента'"
                            :tagColor="getComponentDataStatusSelectedColor('StatusUser')"
                            :style="{'max-width': '190px', 'width': '190px'}"
                            @check="onSelectStatus($event, 'StatusUser')"
                        />
                    </div>

                </div>

                <Description
                    :params="getComponentData('Description')"
                    :isLoading="isLoading"
                    class="construction-info__content-description"
                />

                <drop-zone :class="{ 'construction-info__drop-zone_mobile': isMobile }">
                    <drop-column
                        v-for="(column, index) in draggableCardsByType"
                        :key="index"
                        :id="'drop-column-' + (index+1)"
                        @dropCard="dropCard"
                    >
                        <draggable-card
                            v-for="card in column"
                            :key="card.id"
                            :id="'draggable-card-' + card.id"
                            :column-id="index"
                            :draggable="card.draggable && currentDraggableId === card.id"
                            :is-preview="card.isPreview"
                            :class="card.classes"
                            :isLoading="isLoading"
                            @dragOver="dragOver"
                            @dragEnd="dragEnd"
                        >
                            <component
                                :is="card.componentName"
                                :params="getComponentData(card.componentName)"
                                @mouseDown="onMouseDownDraggableElement($event, card.id)"
                                @removeGroup="removeGroup"
                                @onInputCommentLazy="onInputCommentLazy"
                                @streetView="onStreetView"
                            />
                        </draggable-card>
                    </drop-column>
                </drop-zone>

            </div>
        </div>
    </div>
</template>

<script>
import BaseButtonIcon from "@/components/Base/BaseButtonIcon";
import BaseButton from "@/components/Base/BaseButton";
import BaseSelect from "@/components/Base/BaseSelect";
import BaseDropdown from "@/components/Base/BaseDropdown";
import BaseSvgIcon from "@/components/Base/BaseSvgIcon";
import DatePicker from "@/components/Base/DatePicker";
import DropZone from "@/components/DropZone/DropZone";
import DropColumn from "@/components/DropZone/DropColumn";
import DraggableCard from "@/components/DropZone/DraggableCard";
import Budget from "@/components/ConstructionInfo/Budget";
import Params from "@/components/ConstructionInfo/Params";
import Site from "@/components/ConstructionInfo/Site";
import Comment from "@/components/ConstructionInfo/Comment";
import Maket from "@/components/ConstructionInfo/Maket";
import Projects from "@/components/ConstructionInfo/Projects";
import Photo from "@/components/ConstructionInfo/Photo";
import AdditionalPhoto from "@/components/ConstructionInfo/AdditionalPhoto";
import Notification from "@/components/Notification/Notification";
import Description from "@/components/ConstructionInfo/Description";
import BaseDropdownMenu from "@/components/Base/BaseDropdownMenu";
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
import ServiceConstructionInfo from "../../services/ServicesConstructionInfo/ServiceConstructionInfo";
import SchemeConstructionInfo from "../../schemes/SchemeConstructionInfo";
import UtilDate from "../../utils/UtilDate";
import ManagerDraggableCard from "../../managers/ManagerDraggableCard";
import ServiceCompetitor     from "../../services/ServicesCompetitor/ServiceCompetitor";
import ServiceMapTable from '../../services/ServicesMap/ServiceMapTable.js';

let {draggableCards} = SchemeConstructionInfo;

export default {
    name: "ConstructionInfo",
    components: {
        Description,
        Notification,
        BaseSelect,
        BaseButton,
        BaseDropdown,
        BaseSvgIcon,
        DropZone,
        DropColumn,
        DraggableCard,
        Budget,
        Params,
        Site,
        Comment,
        Maket,
        Projects,
        Photo,
        AdditionalPhoto,
        DatePicker,
        BaseButtonIcon,
        BaseDropdownMenu,
        BasePreloaderSkeleton,
        'IconCalendar': () => import('../../components/Icons/IconCalendar.vue'),
        'IconArrowV2': () => import('../../components/Icons/IconArrowV2.vue'),
    },
    /**
     * Данные компонента
     * @property {Number} constrInfo - инфо о конструкции
     * @property {Object} selectedFilter - выбранный фильтр
     * @property {Array} selectedPriceIds
     */
    props: {
        constrInfo: {
            type: Object,
            default: () => {}
        },
        markerId: {
            type: Number,
            default: null
        },
        sideId: {
            type: Number,
            default: null
        },
        selectedFilter: {
            type: Object,
            default: () => {}
        },
        selectedPriceIds: {
            type: Array,
            default: () => []
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },

    /**
     * Данные компонента
     * @property {String} currentDraggableId
     * @property {Boolean} isDatepickerOpen
     * @property {Object} draggableCards
     * @property {Number} columnId
     * @property {Number} activeSideId
     * @property {Number} activePriceId
     * @property {Array} availablePrices
     * @property {Object} cardData
     */
    data: () => ({
        currentDraggableId: '',
        isDatepickerOpen: false,
        draggableCards: draggableCards,
        hoverCardId: null,
        columnId: null,
        activeSideId: null,
        activePriceId: null,
        availablePrices: [],
        cardData: {},
        sideListShadow: [
            {
                id: '666',
                code: 'A'
            },
            {
                id: '666',
                code: 'B'
            }
        ],
        isLoading: false
    }),

    computed: {
        draggableCardsByType() {
            return this.draggableCards.reduce((cards, rowCards) => {
                const filtered = rowCards.filter(card => this.constrInfo.type !== 'competitor' || card.componentName !== 'Comment');
                cards.push(filtered)
                return cards
            }, [])
        },

        isSelectedPrice(){
            return this.selectedPriceIds.includes(this.activePriceId);
        },
        /**
         * Массив доступных цен для дейтпикера
         */
        availableDates(){
            return this.availablePrices.map((price) => {
                return price.date;
            });
        },

        /**
         * Массив сторон для табов
         */
        sideList(){
            let {sides = []} = this.cardData;
            return sides.map((side) => {
                return {
                    id: side.id,
                    code: side.code
                }
            });
        },

        /**
         * Основной объекта выбранных цен и сторон
         */
        activeSideData(){
            if(!Object.keys(this.cardData).length){
                return {};
            }
            let {sides = [], city = {}, id = "", lat = "", lng = ""} = this.cardData;
            let activeSideIndex = sides.findIndex((item) => {
                return item.id === this.activeSideId;
            });

            let activeSide = activeSideIndex !== -1 ? sides[activeSideIndex] : {};
            let {prices = []} = activeSide;
            let activePriceObj = {};
            if(this.activePriceId !== null){
                let activePriceIndex = prices.findIndex((price) => {
                    let {id} = price;
                    return String(id) === this.activePriceId;
                });
                activePriceObj = prices[activePriceIndex] ?? {}
            }
            const marker = {id, lat, lng};
            activeSide = {
                ...activeSide,
                city,
                marker, 
                activePrice: { ...activePriceObj }, 
                statuses: this.cardData?.statuses ?? [], 
                statusesUser: this.cardData?.user_statuses ?? []
            };

            return activeSide;
        },

        /**
         * Выбранные цены в формате дейтпике
         */
        activePriceDPDates() {
            let {activePrice = {}} = this.activeSideData;
            let {date = ""} = activePrice;
            return date ? [UtilDate.toDatepickerDate(date)] : [];
        },

        /**
         * заголовок для копки даты
         * @returns {String}
         */
        dateTitle() {
            if (this.activePriceDPDates.length === 0) {
                return 'Выберите дату'
            } else {
                let uniqueMonth = this.activePriceDPDates[0];
                return UtilDate.toPrettyString(false, uniqueMonth.month - 1, false, true);
            }
        },
        headerActions() {
            return [
                {
                    tooltip: 'Выбрать размещение',
                    icon: 'push-pin'
                },
                {
                    tooltip: 'Фотоотчет по периодам',
                    icon: 'tabler-icon-camera'
                },
                {
                    tooltip: 'Скопировать ссылку',
                    icon: 'tabler-icon-copy'
                },
            ];
        },

        headerMenuItems() {
            return [
                {value: 'Отвязать макет'},
                {value: 'Создать макет'},
                {value: 'Создать проект'},
            ];
        },
        activeSideCode() {
            const activeSideCode = String(this.activeSideData?.code ?? '').toLowerCase();

            return activeSideCode;
        }
    },

    watch: {
        constrInfo(){
            this.getConstructionData();
        },
        activeSideId() {  
            this.$emit('toggleSide', this.activeSideCode);
        }
    },

    mounted() {
        this.getConstructionData();
    },

    methods: {
        onChangeVisibleDatepicker(boolean) {
            this.isDatepickerOpen = boolean;
        },

        toggleSide(side) {
            this.activeSideId = side.id;
            let {prices = []} = this.activeSideData;
            let availablePrices = ServiceConstructionInfo._getAvailablePrices(this.activeSideData);
            let activePrice = ServiceConstructionInfo._getActivePrice(prices, availablePrices);
            this.activePriceId = activePrice.id;
        },

        removePreviewCard() {
            this.draggableCards.forEach((column) => {
                const previewIndex = column.findIndex((card) => Number(card.id) === 9999);
                if (previewIndex !== -1) {
                    column.splice(previewIndex, 1);
                }
            });
        },

        onMouseDownDraggableElement(evt, cardId) {
            this.currentDraggableId = cardId;
        },

        dragOver(cardId = null, columnId = null) {
            // По дефолту наведение только на DraggableCard показывает зону вставки элемента.
            // Отменяем скрытие зоны, когда курсор находится на ней
            if (Number(cardId) === 9999) {
                return;
            }

            // Если навели курсор на DraggableCard рисуем зону вставки элемента
            if (cardId !== undefined && columnId !== undefined && this.hoverCardId !== Number(cardId)) {
                this.hoverCardId = Number(cardId);
                this.columnId = Number(columnId);

                const previewCard = document.querySelector('.preview-card');
                if (previewCard) {
                    previewCard.style.height = '0';
                    // previewCard.style.transition = 'height .2s ease-out';
                }

                // setTimeout для корректной работы анимации стилей.
                // Чтобы удаление и добавление новой зоны сработало только после применения стилей
                setTimeout(() => {
                    this.removePreviewCard();

                    const hoverItemIndex = this.draggableCards[columnId].findIndex((card) => Number(card.id) === Number(cardId));
                    if (hoverItemIndex !== -1) {
                        this.draggableCards[columnId].splice(
                            hoverItemIndex + 1,
                            0,
                            {id: 9999, draggable: false, componentName: '', isPreview: true}
                        );
                        setTimeout(() => {
                            const previewCard = document.querySelector('.preview-card');
                            if (previewCard) {
                                previewCard.style.height = '130px';
                                // previewCard.style.transition = 'height .2s ease-out';
                            }
                        }, 50);
                    }

                }, 250);

            }
        },

        dropCard(cardId = null, columnId = null) {
            if (cardId !== undefined && columnId !== undefined) {
                console.log('dropCard', cardId, columnId);
                this.removePreviewCard();

                const movedCard = this.draggableCards[columnId].find((card) => Number(card.id) === Number(cardId));
                movedCard.classes = 'dropped-card';

                const movedCardIndex = this.draggableCards[columnId].findIndex((card) => Number(card.id) === Number(cardId));

                if (movedCardIndex !== -1 && Number(this.hoverCardId) !== Number(cardId)) {
                    this.draggableCards[columnId].splice(movedCardIndex, 1);

                    this.draggableCards.forEach((column) => {
                        const hoverCardIndex = column.findIndex((card) => Number(card.id) === Number(this.hoverCardId));
                        if (hoverCardIndex !== -1) {
                            this.draggableCards[this.columnId].splice(hoverCardIndex + 1, 0, {...movedCard, classes: ''});
                        }
                    });

                    setTimeout(() => {
                        const dropedCard = document.querySelector(`#draggable-card-${cardId}`);
                        if (dropedCard) {
                            dropedCard.classList.remove('dropped-card');
                        }
                    }, 0);

                    setTimeout(() => {
                        this.removePreviewCard();
                    }, 300);
                }
                else
                    movedCard.classes = '';
            }
        },

        dragEnd() {
            console.log('dragEnd');
            this.removePreviewCard();
            this.currentDraggableId = '';
        },

        /**
         * Получение данных конструкции
         */
        async getConstructionData() {
            const {entity = null, id = null, kitItemId = null, type = null, dates = [], groupId = null} = this.constrInfo;
            if(entity === null){
                return;
            }
            this.isLoading = true;
            switch (type) {
                case 'competitor':
                    await ServiceCompetitor.postMarkerDetail(id, groupId, dates, this.afterPostCardInfo);
                    break;
                default:
                    await ServiceConstructionInfo.postInfo(entity, id, kitItemId, this.afterPostCardInfo);
            }
            this.isLoading = false;
        },
        /**
         *
         * @param {Object} markerData
         * @param {Object} activeSide
         * @param {Object} activePrice
         * @param {Array} availablePrices
         */
        afterPostCardInfo(markerData, activeSide, activePrice, availablePrices){
            const {id:sideId = null} = activeSide;
            const {id:priceId = null} = activePrice;
            this.activeSideId = sideId;
            this.availablePrices = availablePrices;
            this.activePriceId = priceId;
            this.cardData = markerData;
            this.isLoading = false;
        },
        /**
         * Срабатывает после выбора даты
         * @param data
         */
        onApproveDatepicker(data){
            let selectedDate = data[0];
            let {year, month} = selectedDate;
            let newPriceIndex = this.availablePrices.findIndex((availablePrice) => {
                let {date} = availablePrice;
                let {month: availableMonth, year: availableYear} = date;
                return availableMonth === month && availableYear === year;
            })
            this.activePriceId = newPriceIndex !== -1 ? this.availablePrices[newPriceIndex].priceId : null;
            this.isDatepickerOpen = false;
        },
        /**
         * Отвязка цены от макета/проекта
         * @param data
         */
        removeGroup(data){
            let {groupId, priceId} = data;
            ServiceConstructionInfo.unlinkPriceFromGroup(groupId, priceId, () => {
                this.getConstructionData();
            });
        },
        /**
         * Редактирование комментария
         * @param comment
         */
        onInputCommentLazy(comment){
            ServiceConstructionInfo.setComment(comment, this.activePriceId, (editedPrice) => {
                let {sides = []} = this.cardData;
                let activeSideIndex = sides.findIndex((item) => {
                    return item.id === this.activeSideId;
                });
                let activeSide = activeSideIndex !== -1 ? sides[activeSideIndex] : {};
                let {prices = []} = activeSide;
                let activePriceIndex = prices.findIndex((price) => {
                    let {id} = price;
                    return id === this.activePriceId;
                });
                prices.splice(activePriceIndex, 1, editedPrice);
                let editedActiveSide = {...activeSide, prices};
                sides.splice(activeSideIndex, 1, editedActiveSide);
                this.cardData = {...this.cardData, sides};
            });
        },
        /**
         * Получение данных для переносимой карточки
         * @param componentName
         * @returns {*}
         */
        getComponentData(componentName){
            return Object.keys(this.activeSideData).length
                ? ManagerDraggableCard.getParams(componentName, this.activeSideData)
                : {};
        },
        onStreetView(coords = {}) {
            this.$emit('streetView', coords);
        },
        getComponentDataStatusIsShowed(componentName = '') {
            const componentData = this.getComponentData(componentName);

            return componentData.isShowed;
        },
        getComponentDataStatusIsDisabled(componentName = '') {
            const componentData = this.getComponentData(componentName);

            return componentData.isDisabled;
        },
        getComponentDataStatusList(componentName = '') {
            const componentData = this.getComponentData(componentName);

            return componentData.statusList;
        },
        getComponentDataStatusSelectedId(componentName = '') {
            const componentData = this.getComponentData(componentName);

            return componentData.statusSelectedId;
        },
        getComponentDataStatusSelectedColor(componentName = '') {
            const componentData = this.getComponentData(componentName);

            return componentData.statusSelectedColor;
        },
        onSelectStatus(statusNew = {}, componentName = '') {
            const componentData = this.getComponentData(componentName);

            this.editMapTableStatus('nmp_price', componentData.priceId, componentData.attribute, String(statusNew?.id ?? ''));
        },
        async editMapTableStatus(entityName = '', priceId = '', attribute = '', newValue = '') {
            await ServiceMapTable.editMapTableStatus(entityName, priceId, attribute, newValue, () => {
                this.getConstructionData();

                const statusSelectedData = {
                    rowData: {
                        extraData: {
                            id: String(this.activeSideId),
                            marker_id: String(this.constrInfo?.id ?? '')
                        }
                    },
                    priceId: String(this.activePriceId),
                    newValue,
                    attribute
                };

                this.$emit('onSelectStatus', statusSelectedData, String(this.constrInfo?.kitItemId ?? ''));
            });
        }
    },

}
</script>

<style lang="scss">
.construction-info__header-more .dropdown {
    height: 160px !important;
    padding: 0 15px !important;
}

</style>

<style lang="scss" scoped>
$color-border: #F2F4F5;
$color-gray-400: #C8CAD0;
$color-gray-900: #F2F2F2;
$color-green: #6EC87B;

.construction-info {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 10px;

    width: 550px;
    z-index: 5;
    background: #FFFFFF;
    box-shadow: 2px 3px 20px #3B4A741A;
    border-radius: 10px;
    box-sizing: border-box;

    &_mobile {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    &__drop-zone {
        &_mobile {
            flex-direction: column;
        }
    }

    &__status {
        display: flex;
        margin-bottom: 10px;

        &_mobile {
            flex-direction: column;
        }

        &-item {
            position: relative;
            margin-right: 10px;

            &-title {
                margin-bottom: 5px;
            }

            &_mobile {
                margin-bottom: 10px;
            }
        }
    }

    &__arrow {
        width: 8px;
        height: 8px;

        ::v-deep path {
            fill: #AFB0B4;
        }
    }

    &__icon-component {
        width: 16px;
        height: 16px;
    }

    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        flex-grow: 1;

        padding: 15px 0 10px 30px;

    }

    &__close {
        position: absolute;
        top: 0;
        left: -18px;

        width: 36px;
        height: 36px;

        background-color: #fff;
        font-size: 14px;
        color: $color-gray-400;
        transition: color .4s ease-out;
        border-radius: 4px;

        &:hover, &:active {
            color: #000;
        }

        &_mobile {
            left: -5px;
        }
    }

    &__date-btn {
        // width: 180px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        // padding: 10px 15px;

        flex-shrink: 0;
        display: flex;
        align-items: center;
        height: 36px;
        width: 180px;
        text-align: left;
        // padding: 7px 12px;
        padding: 8px 15px;
        // border-radius: 4px;
        border-radius: 10px;
    }

    &__date-icon {
        font-size: 16px;
        color: #000;
    }

    &__date-title {
        display: block;
        margin-left: 10px;
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        color: #000;

        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-right: 37px;
    }

    &__header-nav {
        display: flex;
    }

    &__header-nav-calendar {
        position: relative;
        margin-right: 20px;
    }

    &__header-nav-sides {
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: auto;
        grid-template-columns: repeat(4, 36px) min-content;
        grid-gap: 5px;
    }

    &__header-button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 36px;
        height: 36px;
        border-radius: 4px;

        border: 1px solid $color-gray-900;
        background-color: $color-gray-900;

        position: relative;

        &--active {
            background-color: #fff;
            border: 1px solid $color-green;
        }

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    &__header-actions {
        display: flex;
    }

    &__actions-button-preview {
        font-size: 16px;
    }

    &__content {
        height: 0;
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;

        padding-right: 30px;
    }

    &__content-notification {
        margin-bottom: 10px;
    }

    &__content-description {
        margin-bottom: 10px;
    }
}

.draggable-content {
    display: flex;
    justify-content: space-between;

    width: 100%;
    max-width: 600px;
    height: 100vh;

    overflow: hidden;

    margin: 0 auto;
    padding: 0;

    &__board {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 300px;
        background: #fff;

        padding: 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    &__card {
        background-color: burlywood;
        height: 300px;
        cursor: pointer;

        padding: 10px;
        margin-bottom: 15px;
    }
}
</style>
