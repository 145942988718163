export default class UtilBytes {
    static _sliceSize = 512;

    static base64ToBlob(base64String = '', mimeType = '') {
        const byteChars = atob(base64String);
        const byteArrays = [];

		for (let offset = 0; offset < byteChars.length; offset += this._sliceSize) {
			const byteCharsSlice = byteChars.slice(offset, offset + this._sliceSize);
            const byteNumbers = byteCharsSlice.split('').map(char => char.charCodeAt(0));

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
        const blobData = new Blob(byteArrays, { type: mimeType });

		return blobData;
    }
}