<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.22199 2.84276C6.04427 2.29328 7.01103 2 8 2C8.98897 2 9.95573 2.29328 10.778 2.84276C11.6003 3.39223 12.2411 4.17321 12.6195 5.08693C12.9979 6.00065 13.0969 7.00606 12.9038 7.97601C12.7108 8.94585 12.2345 9.83667 11.5352 10.5358L8.70723 13.3648C8.51969 13.5524 8.26528 13.6579 8 13.6579C7.73472 13.6579 7.48032 13.5524 7.29277 13.3648L4.465 10.5361C3.76572 9.8369 3.28921 8.94585 3.09619 7.97601C2.90314 7.00606 3.00207 6.00065 3.38046 5.08693C3.75885 4.17321 4.39972 3.39223 5.22199 2.84276ZM8 4C7.40662 4 6.82656 4.17597 6.3332 4.50565C5.83983 4.83534 5.45531 5.30393 5.22828 5.85216C5.00124 6.40039 4.94188 7.00364 5.05771 7.5856C5.17354 8.16757 5.45935 8.70212 5.879 9.12164L8 11.2434L10.1208 9.12187C10.5404 8.70235 10.8265 8.16757 10.9423 7.5856C11.0581 7.00364 10.9988 6.40039 10.7717 5.85216C10.5447 5.30393 10.1602 4.83534 9.66681 4.50565C9.17344 4.17597 8.59338 4 8 4Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 6.00085C8.55228 6.00085 9 6.44857 9 7.00085V7.01085C9 7.56314 8.55228 8.01085 8 8.01085C7.44772 8.01085 7 7.56314 7 7.01085V7.00085C7 6.44857 7.44772 6.00085 8 6.00085Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.222 10.8428C14.0443 10.2933 15.011 10 16 10C16.989 10 17.9557 10.2933 18.778 10.8428C19.6003 11.3922 20.2411 12.1732 20.6195 13.0869C20.9979 14.0007 21.0969 15.0061 20.9038 15.976C20.7108 16.9458 20.2345 17.8367 19.5352 18.5358C19.5352 18.5359 19.5353 18.5358 19.5352 18.5358L16.7072 21.3648C16.5197 21.5524 16.2653 21.6579 16 21.6579C15.7347 21.6579 15.4803 21.5524 15.2928 21.3648L12.465 18.5361C12.4649 18.536 12.4651 18.5361 12.465 18.5361C11.7657 17.8369 11.2892 16.9458 11.0962 15.976C10.9031 15.0061 11.0021 14.0007 11.3805 13.0869C11.7589 12.1732 12.3997 11.3922 13.222 10.8428ZM16 12C15.4066 12 14.8266 12.176 14.3332 12.5057C13.8398 12.8353 13.4553 13.3039 13.2283 13.8522C13.0012 14.4004 12.9419 15.0036 13.0577 15.5856C13.1735 16.1676 13.4594 16.7021 13.879 17.1216L16 19.2434L18.1208 17.1219C18.5404 16.7024 18.8265 16.1676 18.9423 15.5856C19.0581 15.0036 18.9988 14.4004 18.7717 13.8522C18.5447 13.3039 18.1602 12.8353 17.6668 12.5057C17.1734 12.176 16.5934 12 16 12Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 14.0009C16.5523 14.0009 17 14.4486 17 15.0009V15.0109C17 15.5631 16.5523 16.0109 16 16.0109C15.4477 16.0109 15 15.5631 15 15.0109V15.0009C15 14.4486 15.4477 14.0009 16 14.0009Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'IconMarkers'
}
</script>