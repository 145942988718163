<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9ZM8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11Z" fill="#0D1725"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.00031C10.6156 4.00031 9.26219 4.41084 8.11105 5.17999C6.95991 5.94915 6.06271 7.04238 5.53288 8.32144C5.00306 9.6005 4.86441 11.0079 5.13448 12.3658C5.40454 13.7236 6.07115 14.9709 7.05003 15.9498C7.04999 15.9498 7.05006 15.9499 7.05003 15.9498L11.2938 20.1925C11.4813 20.3799 11.7355 20.4851 12.0005 20.4851C12.2655 20.4851 12.5197 20.3799 12.7073 20.1925L16.9499 15.9499C16.9499 15.9499 16.9499 15.9499 16.9499 15.9499C17.9288 14.9709 18.5955 13.7237 18.8655 12.3658C19.1356 11.0079 18.997 9.6005 18.4671 8.32144C17.9373 7.04238 17.0401 5.94915 15.889 5.17999C14.7378 4.41084 13.3845 4.00031 12 4.00031ZM18.3641 17.3641C19.6228 16.1054 20.4799 14.5018 20.8271 12.7559C21.1743 11.0101 20.9961 9.20056 20.3149 7.55605C19.6337 5.91154 18.4801 4.50596 17.0001 3.51705C15.5201 2.52813 13.78 2.00031 12 2.00031C10.22 2.00031 8.47995 2.52813 6.99992 3.51705C5.51989 4.50596 4.36633 5.91154 3.68513 7.55605C3.00393 9.20056 2.82567 11.0101 3.1729 12.7559C3.52013 14.5018 4.37725 16.1054 5.63588 17.3641L9.87998 21.6072C10.4425 22.1692 11.2054 22.4851 12.0005 22.4851C12.7956 22.4851 13.5582 22.1694 14.1208 21.6075L18.3641 17.3641Z" fill="#0D1725"/>
    </svg>
</template>

<script>
export default {
    name: 'IconMarker'
}
</script>