<template>
    <component :is="name" />
</template>

<script>
    export default {
        name: "Icon",
        components: {
            'IconInfo': () => import('./IconInfo.vue'),
            'IconFileDownload': () => import('./IconFileDownload.vue'),
            'IconRocket': () => import('./IconRocket.vue'),
            'IconCopy': () => import('./IconCopy.vue'),
            'IconEraser': () => import('./IconEraser.vue'),
            'IconTrash': () => import('./IconTrash.vue'),
            'IconHamburger': () => import('./IconHamburger.vue'),
            'IconArrow': () => import('./IconArrow.vue'),
            'IconFolders': () => import('./IconFolders.vue'),
            'IconSaves': () => import('./IconSaves.vue'),
            'IconImages': () => import('./IconImages.vue'),
            'IconToggler': () => import('./IconToggler.vue'),
            'IconClose': () => import('./IconClose.vue'),
            'IconShare': () => import('./IconShare.vue'),
            'IconMarker': () => import('./IconMarker.vue'),
            'IconMarkers': () => import('./IconMarkers.vue')
        },
        props: {
            name: {
                type: String,
                default: ''
            },
        }
    }
</script>

<style scoped>

</style>
