const SchemeKitTable = {
    kitTableColumnsBase: [
        {
            prop: 'photo',
            label: 'Фото',
            isShowColumn: true,
            isSlot: true,
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '80px'
            },
        },
        {
            prop: 'oohdesk_id',
            label: 'OOHDESK ID',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
        },
        {
            prop: 'opn_number',
            label: '№ ОПН',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isCustomizable: false,
            isClickEmit: true,
            isSticky: true,
            stickyPosition: 1,
            columnStyles: {
                width: '80px',
                position: "sticky",
                left: "54px",
                zIndex: "1",
                "box-shadow": "5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            },
        },
        {
            prop: 'city',
            label: 'Город',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'supp_item_id',
            label: 'GID',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['gid-cell'],
        },
        {
            prop: 'realAddress',
            label: 'Адрес',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isCustomizable: false,
            isClickEmit: true,
            isSticky: true,
            stickyPosition: 2,
            columnStyles: {
                width: '300px',
                position: "sticky",
                left: "0",
                zIndex: "1",
                "box-shadow": "5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            },
            classes: ['address-cell'],
            maxLength: 80,
        },
        {
            prop: 'code',
            label: 'Сторона',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                textAlign: 'center',
                width: '90px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'supp_id',
            label: 'Оператор',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'format_id',
            label: 'Формат',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['format-cell']
        },
        {
            prop: 'lighted',
            label: 'Свет',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'image_link',
            label: 'Ссылка на фото',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isSlot: true,
            columnStyles: {
                width: '100px',
            }
        },
        {
            prop: 'ots',
            label: 'OTS Агентский',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp',
            label: 'GRP Агентский',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['grp-cell']
        },
        {
            prop: 'espar_id',
            label: 'ESPAR ID',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            },
            classes: ['espar_id-cell']
        },
        {
            prop: 'ots_system',
            label: 'OTS',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp_system',
            label: 'GRP',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['grp-cell']
        },
        {
            prop: 'material',
            label: 'Материал',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['material-cell'],
        },
        {
            prop: 'move_direction',
            label: 'Направление движения',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['move_direction-cell'],
        },
        {
            prop: 'print_price',
            label: 'Печать за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['print_price-cell'],
            entity: 'nmp_side'
        },
        {
            prop: 'install_price',
            label: 'Монтаж за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['install_price-cell'],
            entity: 'nmp_side'
        },
        {
            prop: 'additional_install_price',
            label: 'Доп монтаж за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['additional_install_price-cell'],
        },
        {
            prop: 'ext_field_1',
            label: 'Дополнительное поле 1',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_2',
            label: 'Дополнительное поле 2',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_3',
            label: 'Дополнительное поле 3',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_4',
            label: 'Дополнительное поле 4',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_5',
            label: 'Дополнительное поле 5',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_6',
            label: 'Дополнительное поле 6',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_7',
            label: 'Дополнительное поле 7',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_8',
            label: 'Дополнительное поле 8',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_9',
            label: 'Дополнительное поле 9',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_10',
            label: 'Дополнительное поле 10',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'lat',
            label: 'Широта',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'lng',
            label: 'Долгота',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'direction',
            label: 'Азимут',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px'
            }
        },
        {
            prop: 'period_count',
            label: 'Периодов',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px'
            }
        },
        // {
        //     prop: 'direction',
        //     label: 'Азимут',
        //     isShowColumn: true,
        //     canSearch: false,
        //     canSorted: false,
        //     isClickEmit: true,
        //     columnStyles: {
        //         width: '70px'
        //     }
        // },
        {
            prop: 'total_price',
            label: 'Итого цена оператора, ₽',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            }
        },
        {
            prop: 'total_agency_price',
            label: 'Итого цена агентства, ₽',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            }
        }
    ],
    kitTableColumnsBaseMobile: [
        {
            prop: 'photo',
            label: 'Фото',
            isShowColumn: true,
            isSlot: true,
            canSearch: false,
            canSorted: false,
            columnStyles: {
                width: '80px'
            },
        },
        {
            prop: 'oohdesk_id',
            label: 'OOHDESK ID',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
        },
        {
            prop: 'opn_number',
            label: '№ ОПН',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isCustomizable: false,
            isClickEmit: true,
            // isSticky: true,
            // stickyPosition: 1,
            columnStyles: {
                width: '80px',
            },
        },
        {
            prop: 'city',
            label: 'Город',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'supp_item_id',
            label: 'GID',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['gid-cell'],
        },
        {
            prop: 'realAddress',
            label: 'Адрес',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isCustomizable: false,
            isClickEmit: true,
            // isSticky: true,
            // stickyPosition: 2,
            columnStyles: {
                width: '300px'
            },
            classes: ['address-cell'],
            maxLength: 80,
        },
        {
            prop: 'code',
            label: 'Сторона',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                textAlign: 'center',
                width: '90px'
            },
            classes: ['code-cell']
        },
        {
            prop: 'supp_id',
            label: 'Оператор',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['supplier-cell']
        },
        {
            prop: 'type_id',
            label: 'Тип',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'kind_id',
            label: 'Вид',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            }
        },
        {
            prop: 'format_id',
            label: 'Формат',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['format-cell']
        },
        {
            prop: 'lighted',
            label: 'Свет',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
            }
        },
        {
            prop: 'image_link',
            label: 'Ссылка на фото',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isSlot: true,
            columnStyles: {
                width: '100px',
            }
        },
        {
            prop: 'ots',
            label: 'OTS Агентский',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp',
            label: 'GRP Агентский',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['grp-cell']
        },
        {
            prop: 'espar_id',
            label: 'ESPAR ID',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            },
            classes: ['espar_id-cell']
        },
        {
            prop: 'ots_system',
            label: 'OTS',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['ots-cell']
        },
        {
            prop: 'grp_system',
            label: 'GRP',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['grp-cell']
        },
        {
            prop: 'material',
            label: 'Материал',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['material-cell'],
        },
        {
            prop: 'move_direction',
            label: 'Направление движения',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['move_direction-cell'],
        },
        {
            prop: 'print_price',
            label: 'Печать за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['print_price-cell'],
            entity: 'nmp_side'
        },
        {
            prop: 'install_price',
            label: 'Монтаж за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['install_price-cell'],
            entity: 'nmp_side'
        },
        {
            prop: 'additional_install_price',
            label: 'Доп монтаж за единицу, ₽',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            },
            classes: ['additional_install_price-cell'],
        },
        {
            prop: 'ext_field_1',
            label: 'Дополнительное поле 1',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_2',
            label: 'Дополнительное поле 2',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_3',
            label: 'Дополнительное поле 3',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_4',
            label: 'Дополнительное поле 4',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_5',
            label: 'Дополнительное поле 5',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_6',
            label: 'Дополнительное поле 6',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_7',
            label: 'Дополнительное поле 7',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_8',
            label: 'Дополнительное поле 8',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_9',
            label: 'Дополнительное поле 9',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'ext_field_10',
            label: 'Дополнительное поле 10',
            isShowColumn: true,
            canSearch: true,
            canSorted: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px'
            },
            classes: ['ext_field-cell'],
        },
        {
            prop: 'lat',
            label: 'Широта',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'lng',
            label: 'Долгота',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '100px'
            }
        },
        {
            prop: 'direction',
            label: 'Азимут',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px'
            }
        },
        {
            prop: 'period_count',
            label: 'Периодов',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px'
            }
        },
        {
            prop: 'direction',
            label: 'Азимут',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                width: '70px'
            }
        },
        {
            prop: 'total_price',
            label: 'Итого цена оператора, ₽',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            }
        },
        {
            prop: 'total_agency_price',
            label: 'Итого цена агентства, ₽',
            isShowColumn: true,
            canSearch: false,
            canSorted: false,
            isClickEmit: true,
            columnStyles: {
                'width': '120px',
                'text-align': 'right',
                'justify-content': 'end',
                'cursor': 'pointer',
            }
        }
    ],
    kitTableColumnsPrices: [
        {
            prop: 'agency_price',
            label: 'Цена агенства, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'install_price',
            label: 'Цена монтаж, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell'],
            entity: 'nmp_price'
        },
        {
            prop: 'additional_install_price',
            label: 'Цена доп. монтаж, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'print_price',
            label: 'Цена печать, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell'],
            entity: 'nmp_price'
        },
        {
            prop: 'additional_print_price',
            label: 'Цена доп. печать, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'price',
            label: 'Прайс, ₽',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'discount',
            label: 'Скидка, %',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '100px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'video_frequency',
            label: 'Выходов в сутки',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'video_block_clients',
            label: 'Выходов в блоке',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'video_block',
            label: 'Хронометраж блока, сек',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'video_time',
            label: 'Хронометраж ролика, сек',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'period_date_from',
            label: 'Начало периода',
            isSlot: true,
            isDateColumn: true,
            isShowColumn: true,
            columnStyles: {
                width: '190px',
            },
        },
        {
            prop: 'period_date_to',
            label: 'Конец периода',
            isSlot: true,
            isDateColumn: true,
            isShowColumn: true,
            columnStyles: {
                width: '190px',
            },
        },
        {
            prop: 'mount_date_plan',
            label: 'Дата монтажа план',
            isSlot: true,
            isDateColumn: true,
            isShowColumn: true,
            // isClickEmit: true,
            columnStyles: {
                // width: '100px',
                // textAlign: 'right',
                // 'justify-content': 'end'
                width: '190px',
            },
            // classes: ['date-cell']
        },
        {
            prop: 'mount_date_fact',
            label: 'Дата монтажа факт',
            isSlot: true,
            isDateColumn: true,
            isShowColumn: true,
            // isClickEmit: true,
            columnStyles: {
                // width: '100px',
                // textAlign: 'right',
                // 'justify-content': 'end'
                width: '190px',
            },
            // classes: ['date-cell']
        },
        {
            prop: 'brand_id',
            label: 'Бренд',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '150px'
            },
            classes: ['brand-cell']
        },
        {
            prop: 'layout_names',
            label: 'Макеты',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
        {
            prop: 'project_names',
            label: 'Проекты',
            isShowColumn: true,
            isClickEmit: true,
            columnStyles: {
                width: '120px',
                textAlign: 'right',
                'justify-content': 'end'
            },
            classes: ['date-cell']
        },
    ],
    kitTableColumnsStatus: [
        {
            prop: 'status',
            label: 'Статус агентства',
            isShowColumn: true,
            isSlot: true,
            isDropdownColumn: true,
            columnStyles: {
                width: '190px',
            },
        },
        {
            prop: 'user_status',
            label: 'Статус клиента',
            isShowColumn: true,
            isSlot: true,
            isDropdownColumn: true,
            columnStyles: {
                width: '190px',
            },
        },
    ]
};

export default SchemeKitTable;
