<template>
    <div class="layout">
        <div class="layout__main">
            <CapWithSidebar
                view="transparent"
                class="layout__cap"
                :title="title || $route.meta.title || 'Oohdesk'"
                :logo-src="capLogoSrc"
                :config="capConfig"
                :status="preparedLastStatus"
                :user-menu-data="userProfile"
                :sharedUsersData="usersShared"
                :userMenuDropdownItems="userMenuDropdownItems"
                :historyItems="historyItems"
                :isShowAppMenu="isMenuOpen"
                :host="host"
                :menu-list="sidebarMenuItems"
                :isMobile="isMobile"
                @onHamburger="onHamburger"
                @onHistoryDropdownChangeVisible="historyDropdownChangeVisible"
                @onUserMenuLogout="logout"
                @onUserMenuItem="onUserMenuItem"
                @onClickUserShared="onClickUserShared"
                @onMenuItemClick="onMenuItemClick"
            >
                <template v-slot:content>
                    <div class="layout__content">
                        <slot />
                    </div>
                </template>
            </CapWithSidebar>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import IconProfile from "@/components/Icons/IconProfile";
import IconBell from "@/components/Icons/IconBell";
import IconImport from "@/components/Icons/IconImport";
import IconLink from "@/components/Icons/IconLink";
import IconAccess from "@/components/Icons/IconAccess";
import IconSettingsV2 from "@/components/Icons/IconSettingsV2.vue";
import ServiceAuth from "@/services/ServicesAuth/ServiceAuth";
import ServiceUser from "@/services/ServicesAuth/ServiceUser";
import ServiceFilter from "@/services/ServicesFilter/ServiceFilter";
import MixinApi from "@/mixins/MixinsPageBayuerdesk/MixinApi";
import MixiHistory from "@/mixins/MixiHistory";
import SchemeUserMenu from "../schemes/SchemeUserMenu";
const { userMenuDropdownItems } = SchemeUserMenu;

const APP_HOST = process.env.VUE_APP_EXTRA_PRODUCT_HOST;

export default {
    name: "LayoutUserProfile",
    mixins: [MixinApi, MixiHistory],

    data() {
        return {
            isLoading: true,
            isMenuOpen: false,
            usersShared: [],
            lastDate: "",
            title: "",
            userMenuDropdownItems: [...userMenuDropdownItems],
            host: APP_HOST,
            sidebarMenuItems: [
                {
                    id: "profile",
                    text: "Мой профиль",
                    icon: IconProfile,
                    link: "https://map.oohdesk.ru/profile",
                    isActive: true,
                },
                {
                    id: "access",
                    text: "Права доступа",
                    icon: IconAccess,
                    link: "https://map.oohdesk.ru/profile/access",
                    isActive: false,
                },
                {
                    id: "links",
                    text: "Менеджер ссылок",
                    icon: IconLink,
                    link: "https://map.oohdesk.ru/links",
                    isActive: false,
                },
                {
                    id: "import",
                    text: "Импорт",
                    icon: IconImport,
                    link: "https://map.oohdesk.ru/import",
                    isActive: false,
                },
                {
                    id: "notofies",
                    text: "Уведомления",
                    icon: IconBell,
                    link: "https://map.oohdesk.ru/profile/edit-email",
                    isActive: false,
                },
                {
                    id: "settings",
                    text: "Настройки",
                    icon: IconSettingsV2,
                    link: "https://map.oohdesk.ru/settings",
                    isActive: false,
                },
            ],
        };
    },

    async created() {
        await this.fetchLastUpdate();
    },

    mounted() {
        this.setActiveSidebarItem(window.location.pathname)
    },

    async beforeCreate() {
        this.isLoading = true;

        await ServiceUser.getUsersShared(
            (usersShared) => (this.usersShared = usersShared)
        );

        this.isLoading = false;
    },

    computed: {
        ...mapState({
            currentComponent: (state) => state.currentComponent,
            userProfile: state => state.auth.userProfile,
            isMobile: state => state.isMobile,
        }),

        preparedLastStatus() {
            return this.lastDate.length ? "Обновлено " + this.lastDate : "";
        },

        isGuest() {
            return this.isShare && !Object.keys(this.userProfile).length;
        },

        isShare() {
            const { query = {} } = this.$route;
            const { shareToken = null } = query;
            return shareToken !== null;
        },

        layoutContentStyle() {
            const layoutContentStyle =
                this.currentComponent === "PageMap" ? { padding: "0px" } : {};
            return layoutContentStyle;
        },

        capConfig() {
            return {
                isConfigHamburger: false,
                isConfigStatus: true,
                isConfigNotification: !this.isGuest,
                isConfigSettings: false,
                isConfigImport: false,
                isConfigUserMenu: !this.isGuest,
            };
        },

        capLogoSrc() {
            return require("@/assets/images/logo-new.svg");
        },
    },

    methods: {
        onMenuItemClick(data) {
            const link = new URL(data.link);

            if (["profile", "access"].includes(data.id )) {
                this.$router.push(link.pathname);
                this.setActiveSidebarItem(link.pathname)
            } else {
                window.location.replace(link);
            }
        },

        setActiveSidebarItem(pathname) {
            this.sidebarMenuItems.forEach(item => {
                const link = new URL(item.link);

                if (link.pathname === pathname) {
                    item.isActive = true;
                } else {
                    item.isActive = false;
                }
            });
        },

        async fetchLastUpdate() {
            await ServiceFilter.getLastUpdate((result) => {
                const { last_date: lastDate = "", title = "" } = result;
                this.lastDate = lastDate;
                this.title = title;
            });
        },

        async postSwitchUserShared(userShared = {}) {
            await ServiceUser.postSwitchUserShared(userShared, () =>
                window.location.reload()
            );
        },

        onClickUserShared(userShared = {}) {
            this.postSwitchUserShared(userShared);
        },

        onHamburger(isShow) {
            this.isMenuOpen = isShow;
        },

        async logout() {
            await ServiceAuth.logout();
        },

        historyDropdownChangeVisible(isShow) {
            if (isShow && this.historyItems.length === 0) {
                this.postHistory(this.afterPostHistory);
            }
        },

        onUserMenuItem(key = "") {
            ServiceUser.goToUserMenuItem(key);
        },
    },
};
</script>

<style lang="scss">
.layout {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__content {
        position: relative;
        height: 100%;
        padding: 30px;
        background: #f3f6f9;
    }
}
</style>
