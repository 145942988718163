<template>
    <div
        :class="{ 
            'isActive': isVisibleSidePanel && !isMobile,
            'isActive_mobile': isVisibleSidePanel && isMobile
        }"
        class="side-panel"
    >
        <SidePanelTabs
            :tabs="tabs"
            :activeTabId="activeTabId"
            @onSwitchActiveTab="switchActiveTab"
        />
        <div class="side-panel__btn">
            <button
                class="side-panel__btn-close icon-tabler-icon-x"
                @click="toggleSidePanel"
            />
        </div>

        <div class="side-panel__content">
            <div style="margin-bottom: 20px">
                <h3>{{ tabTitle }}</h3>
                <p style="margin: 5px 0 10px">Найдено: {{ kitItems.length }}</p>
            </div>

            <div class="side-panel__content-items">
                <KitPanel
                    direction="column"
                    :kitItems="kitItems"
                    :kitActions="kitActions"
                    :isLoading="isLoading"
                    @onRename="onRenameKit"
                    @onToggleMarkers="onToggleMarkers"
                    @onToggleTable="onToggleTable"
                    @onToggleSummary="onToggleSummary"
                    @onPagination="onPaginationKitList"
                    @onExport="onExport"
                    @onShare="onShare"
                    @onToggleAdmetrix="onToggleAdmetrix"
                    @onRemoveKit="onRemoveKit"
                    @onMore="onMoreKit"
                />
            </div>
        </div>
    </div>
</template>

<script>
// import IconToggler from "../../components/Icons/IconToggler.vue";
import SidePanelTabs from "./SidePanelTabs.vue";
import KitPanel from "../KitPanel/KitPanel.vue";
import SchemeKitPanel from "../../schemes/SchemeKitPanel";
const { kitSidePanelActions, sidePanelTabs, sidePanelTabsAdditionalMobile } = SchemeKitPanel;

export default {
    components: {
        // IconToggler,
        SidePanelTabs,
        KitPanel,
    },
    props: {
        kitItems: {
            type: Array,
            default: () => [],
        },
        activeTabId: {
            type: String,
            default: "",
        },
        isVisibleSidePanel: {
            type: Boolean,
            default: false,
        },
        // kitActions: {
        //     type: Array,
        //     default: () => [],
        // },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // tabs: [...sidePanelTabs],
            kitActions: [...kitSidePanelActions],
        };
    },
    computed: {
        tabs() {
            return this.isMobile ? [...sidePanelTabsAdditionalMobile, ...sidePanelTabs] : [...sidePanelTabs];
        },
        tabTitle() {
            return this.tabs.find((tab) => tab.id === this.activeTabId)?.title;
        },
    },
    methods: {
        onRenameKit(kitItemNameNew, kitItemId) {
            this.$emit("onRename", kitItemNameNew, kitItemId);
        },
        onToggleMarkers(kitActionId, kitItemId) {
            this.$emit("onToggleMarkers", kitActionId, kitItemId);
        },
        onToggleTable(kitActionId, kitItemId) {
            this.$emit("onToggleTable", kitActionId, kitItemId);
        },
        onToggleSummary(kitActionId, kitItemId) {
            this.$emit("onToggleSummary", kitActionId, kitItemId);
        },
        onPaginationKitList(kitActionId, kitItemId) {
            this.$emit("onPagination", kitActionId, kitItemId);
        },
        onExport(kitActionId, kitItemId) {
            this.$emit("onExport", kitActionId, kitItemId);
        },
        onShare(kitActionId, kitItemId) {
            this.$emit("onShare", kitActionId, kitItemId);
        },
        onToggleAdmetrix(kitActionId, kitItemId) {
            this.$emit("onToggleAdmetrix", kitActionId, kitItemId);
        },
        onRemoveKit(kitActionId, kitItemId) {
            this.$emit("onRemoveKit", kitActionId, kitItemId);
        },
        onMoreKit(kitActionId, kitItemId) {
            this.$emit("onMore", kitActionId, kitItemId);
            this.$emit("onMore", kitActionId, kitItemId);
        },
        toggleSidePanel() {
            this.$emit("onToggleSidePanel");
        },
        switchActiveTab(tabId) {
            this.$emit("onSwitchActiveTab", tabId);
        },
    },
};
</script>

<style lang="scss">
.side-panel {
    position: relative;
    overflow-x: hidden;

    min-width: auto;
    width: 66px;
    height: 100%;

    transition: width ease-out 0.3s;

    &.isActive {
        width: 300px;
        overflow-y: auto;

        &_mobile {
            width: 100vh;
            overflow-y: auto;
        }
    }

    &__content {
        position: absolute;
        top: 10px;
        left: 66px;
        width: 220px;
        display: none;
    }

    &.isActive &__content {
        display: block;
    }

    &.isActive_mobile &__content {
        display: block;
    }

    &__btn {
        position: absolute;
        top: 0;
        right: 10px;
        transform: rotate(180deg);
        z-index: 3;

        display: none;
        align-items: center;
        justify-content: center;
        align-self: center;

        width: 36px;
        height: 36px;

        &:hover,
        &:active {
            color: #000;
        }

        & button {
            width: 100%;
            height: 100%;
        }
    }

    &.isActive &__btn {
        transform: rotate(0deg);
        display: flex;
        align-self: baseline;
    }

    &.isActive_mobile  &__btn {
        transform: rotate(0deg);
        display: flex;
        align-self: baseline;
    }
}
</style>
