<template>
    <div class="layout-modules">
        <WidgetMenu
            v-if="filterCityId.length && widgetMenuItems.length > 0"
            class="widget-menu"
            :active-checkbox-list="widgetMenuLabels"
            orientation="vertical"
            :config-show-widgets="widgetMenuItems"
            @onClickWidget="onClickWidget"
        />
        <BaseTransition>
            <ContainerCompetitor
                v-if="widgetOpenedOnce.includes('competitor')"
                v-show="activeWidgetName === 'competitor'"
                @onDrawCompetitorMarkers="onDrawCompetitorMarkers"
                @onClearCompetitorMarkers="onClearCompetitorMarkers"
                @onChangeCompetitorColor="onChangeCompetitorColor"
            />
        </BaseTransition>
        <BaseTransition>
            <ContainerPoi
                v-if="widgetOpenedOnce.includes('poi')"
                v-show="activeWidgetName === 'poi'"
                ref="containerPoi"
                :clickedMoiPoiCoords="moiPoiCoords"
                :selectedCityFilter="filterCityId"
                @setActiveLabel="setActiveLabel"
                @onDrawPoiMarkers="onDrawPoiMarkers"
                @onFilterPoiRadius="onFilterPoiRadius"
                @onClearPoiMarkers="onClearPoiMarkers"
                @onUpdateColorMarkers="onUpdateColorMarkers"
                @onUpdateIconMarkers="onUpdateIconMarkers"
                @onTogglePoiMarker="onTogglePoiMarker"
                @onTogglePoiAddress="onTogglePoiAddress"
                @changeActiveList="setActiveLabel"
                @onClosePoi="onClosePoi"
            />
        </BaseTransition>
    </div>
</template>

<script>
import ContainerPoi from "../../../ContainerPoi/ContainerPoi";
import ContainerCompetitor from "../ContainerCompetitor/ContainerCompetitor";
import BaseTransition from '../../../Base/BaseTransition.vue';
import MixinApi from '@/mixins/MixinsPageBayuerdesk/MixinApi';
import {mapState} from "vuex";
import ServiceShare from "../../../../services/ServicesAuth/ServiceShare";

export default {
    name: "LayoutModules",
    components: {
        ContainerCompetitor,
        ContainerPoi,
        BaseTransition
    },
    mixins: [
        MixinApi,
    ],
    props: {
        moiPoiCoords: {
            type: Array,
            default: () => []
        }
    },
    /**
     * Данные компонента
     * @property {String} activeWidgetName - Имя активного виджета
     * @property {Boolean} isActiveLabel - Показать/скрыть иконку галочки у первой панели
     */
    data: () => ({
        activeWidgetName: '',
        widgetMenuLabels: [],
        widgetOpenedOnce: [],
        isActiveLabel: false,
        widgetMenuConfig: ['competitor', 'poi'],
        // widgetMenuConfig: ['competitor'],
        widgetMenuItems: [],
        sharedParams: {}
    }),
    computed: {
        /**
         * Проксируем состояния из стора
         * @returns {Object} state - состояния
         * @returns {Object} state.filterSelectedData - выбранные данные фильтра
         * @returns {Array<Number|String>} state.filterSelectedData.city_id - выбранные идентификаторы городов фильтра
         * @returns {Array<String>} state.filterSelectedData.date - выбранные даты фильтра
         */
        ...mapState({
            filterSelectedData: state => state.filter.filterSelectedData
        }),

        filterCityId() {
            // const { city_id = []} = this.filterSelectedData;
            const locations = this.filterSelectedData?.location_id ?? [];
            const regions = this.filterSelectedData?.region_id ?? [];
            const city_id = locations.length ? locations : regions;

            return city_id;
        },
        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },
    },
    beforeMount() {
        if(this.isShare){
            ServiceShare.getLinkInfo((params) => {
                this.sharedParams = params;
                const {controls = []} = this.sharedParams;
                const activeWidgets = Array.isArray(this.sharedParams) ? this.sharedParams : Object.keys(this.sharedParams)
                this.widgetMenuItems = this.widgetMenuConfig.filter(menu => activeWidgets.includes(String(menu)));
                activeWidgets.forEach((activeWidget) => {
                    if(this.widgetMenuItems.includes(activeWidget)){
                        this.activeWidgetName = activeWidget;
                        this.widgetOpenedOnce.push(activeWidget);
                        this.activeWidgetName = '';
                    }
                })

                switch (true) {
                    case controls.includes('pedestrianTraffic'):
                        this.$emit("onToggleTraffic", "pedestrianTraffic");
                        break;
                    case controls.includes("autoTraffic"):
                        this.$emit("onToggleTraffic", "autoTraffic");
                        break;
                    case controls.includes("jamsTraffic"):
                        this.$emit("onToggleTraffic", "jamsTraffic");
                        break;
                }

                let markerType = 'default';
                const isMarkerOpnMode = controls.includes("opn");
                switch (true) {
                    case controls.includes("directionAngleView"):
                        markerType = 'directionAngleView';
                        break;
                    case controls.includes("directionFlowSide"):
                        markerType = 'directionFlowSide';
                        break;
                }

                if(isMarkerOpnMode || markerType !== 'default'){
                    this.$emit("onToggleMarkerMode", markerType, isMarkerOpnMode);
                }
            });
        } else
            this.widgetMenuItems = [...this.widgetMenuConfig];
    },
    methods: {
        onDrawCompetitorMarkers(itemId, markers){
            this.$emit('onDrawCompetitorMarkers', itemId, markers);
        },

        onClearCompetitorMarkers(groupId){
            this.$emit('onClearCompetitorMarkers', groupId);
        },

        onChangeCompetitorColor(groupId, color){
            this.$emit('onChangeCompetitorColor', groupId, color);
        },
        /**
         * Клик по элементу меню
         * @param {String} emitName - Имя события кнопки
         * @param {Boolean} isActiveWidget - Включен/выключен элемент
         */
        onClickWidget(emitName = '', isActiveWidget = false) {
            this.activeWidgetName = '';
            setTimeout(() => {
                if (emitName === 'onTogglePoi') {
                    this.activeWidgetName = isActiveWidget ? 'poi' : '';
                }
                if (emitName === 'onToggleСompetitor') {
                    this.activeWidgetName = isActiveWidget ? 'competitor' : '';
                }
                if(this.activeWidgetName && !this.widgetOpenedOnce.includes(this.activeWidgetName)){
                    this.widgetOpenedOnce.push(this.activeWidgetName);
                }
            }, 450);
        },

        onClosePoi() {
            this.activeWidgetName = '';
        },

        /**
         * Показать лейбл, если есть выбранные группы
         * @property {String} widgetName - Имя виджета
         * @property {Boolean} isActive - Показать/скрыть лейбл выбранных групп
         */
        setActiveLabel(widgetName = '', isActive = false) {
            const widgetIndex = this.widgetMenuLabels.findIndex((el) => String(el) === String(widgetName));

            if (isActive && widgetIndex === -1) {
                this.widgetMenuLabels.push(widgetName);
            }

            if (!isActive && widgetIndex !== -1) {
                this.widgetMenuLabels.splice(widgetIndex, 1);
            }
        },

        /**
         * Показать все POI данной группы на карте
         * @param {Array} dataPoi - Список POI
         */
        onDrawPoiMarkers(dataPoi) {
            this.$emit('onDrawPoiMarkers', dataPoi);
        },

        /**
         * @param {Number} radius
         */
        onFilterPoiRadius(radius){
            this.$emit('onFilterPoiRadius', radius);
        },

        /**
         * Удалить все маркеры группы с карты
         * @param {String} groupId
         */
        onClearPoiMarkers(groupId){
            this.$emit('onClearPoiMarkers', groupId);
        },

        /**
         * Обновить цвет маркеров
         * @param {String} groupId
         * @param {String} color
         */
        onUpdateColorMarkers(groupId, color){
            this.$emit('onUpdateColorMarkers', groupId, color);
        },

        /**
         * Обновить иконки маркеров
         * @param {String} groupId
         * @param {String} src
         */
        onUpdateIconMarkers(groupId, src){
            this.$emit('onUpdateIconMarkers', groupId, src);
        },

        /**
         * Изменить активность маркера
         * @param {Object} marker
         */
        onTogglePoiMarker(marker){
            this.$emit('onTogglePoiMarker', marker);
        },

        onTogglePoiAddress(marker) {
            this.$emit('onTogglePoiAddress', marker);
        }
    },
}
</script>

<style lang="scss" scoped>
    .layout-modules {
        display: flex;
        align-items: flex-start;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 5;
    }
</style>
