<template>
    <BaseDropdown
        ref="BaseDropdown"
        :isFixed="isFixed"
        :isVirtualScroll="isVirtualScroll"
        :spacerHeight="containerHeight + 55"
        :padding="padding"
        :disabled="isDisabled"
        :widthCustom="widthCustom"
        :class="['select', { isFullWidth }]"
        :isFullWidth="isFullWidth"
        v-bind="$attrs"
        @changeVisible="onDropdown"
        @closeDropdown="onCloseDropdown"
    >
        <template #preview>
            <BaseButton
                :active="localOpen"
                :style="{ background: previewColor }"
                class="select-preview"
                :class="{ 'select-preview_disabled': isDisabled }"
                :disabled="isDisabled"
                :view="isBorder ? 'border' : 'primary'"
            >
                <div v-if="isCustomPreview" class="select-preview__circle">
                    <base-icon
                        icon="icon-tick"
                        size="sm"
                        color="#fff"
                        class="select-preview__circle-icon"
                    />
                </div>

                <span
                    v-if="previewIcon"
                    class="select-preview__icon"
                    :class="previewIcon"
                ></span>

                <div class="select-preview__container-label">
                    <BaseColorTag
                        v-if="tagColor !== ''"
                        :color="tagColor"
                        class="select-preview__circle"
                    />
                    <span class="select-preview__label" :title="title">
                        {{ title }}
                    </span>
                </div>

                <span
                    class="select-preview__arrow icon-tabler-icon-chevron-down"
                ></span>
            </BaseButton>
        </template>

        <template #content>
            <div class="select-dropdown">
                <div class="select-dropdown__triangle" />
                <!-- HEADER -->
                <div v-if="$slots.header" class="select-dropdown__header">
                    <div class="select-dropdown__top">
                        <slot name="header" />
                    </div>
                </div>

                <!-- SEARCH -->
                <div v-if="canSearch" class="select-dropdown__search">
                    <input
                        v-model="searchValue"
                        type="text"
                        placeholder="Поиск"
                    />
                </div>

                <!-- OPTIONS -->
                <div
                    ref="selectOptionsContainer"
                    class="select-options"
                    @scroll="onScroll"
                >
                    <div ref="spacer" :style="getSpacerStyle()">
                        <div
                            v-for="(option, index) of virtualOptions"
                            :key="index"
                            :class="[
                                'select-options__item',
                                { selected: option.isSelected, isFullWidth },
                                getOptionClasses(option),
                            ]"
                            :style="getOptionStyle()"
                            :title="isVirtualScroll ? option.value : null"
                            @click="onCheck(option)"
                        >
                            <span
                                v-tooltip="getTooltip(option.value)"
                                class="select-option__item-text"

                            >
                                {{ slicedValue(option.value) }}
                            </span>
                            <div
                                v-if="isFullWidth && option.isSelected && isCanRemove && option.value === defaultTitle"
                                class="select-options__item-close"
                                @click.stop="onRemoveChecked(option)"
                            >
                                x
                            </div>
                            <div
                                v-if="getIsRequired(option)"
                                class="select-options__item_required"
                            >
                                *
                            </div>
                        </div>
                    </div>
                </div>

                <!-- FOOTER -->
                <div v-if="$slots.footer" class="select-dropdown__footer">
                    <slot name="footer" />
                </div>
            </div>
        </template>
    </BaseDropdown>
</template>

<script>
import BaseButton from "./BaseButton";
import BaseDropdown from "./BaseDropdown";
import BaseIcon from "@/components/Base/BaseIcon";
import BaseColorTag from "../Base/BaseColorTag.vue";
export default {
    name: "BaseSelect",
    components: {
        BaseButton,
        BaseDropdown,
        BaseIcon,
        BaseColorTag,
    },
    props: {
        /**
         * @property {Array} options - массив опций
         * @property {Array} value - выбранное значение по умолчанию
         */
        options: {
            type: Array,
            default: () => [],
        },
        value: {
            type: [String, Number, Array],
            default: null,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        defaultTitle: {
            type: [String, Number],
            default: "Выбрать значение",
        },
        canSearch: {
            type: Boolean,
            default: false,
        },
        isCloseAfterCheck: {
            type: Boolean,
            default: true,
        },
        open: {
            type: Boolean,
            default: false,
        },
        isCustomPreview: {
            type: Boolean,
            default: false,
        },
        isCanRemove: {
            type: Boolean,
            default: false,
        },
        previewColor: {
            type: String,
            default: "",
        },
        tagColor: {
            type: String,
            default: "",
        },
        isFixed: {
            type: Boolean,
            default: false,
        },
        isVirtualScroll: {
            type: Boolean,
            default: false,
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({}),
        },
        padding: {
            type: Number,
            default: 10,
        },
        maxLengthItem: {
            type: Number,
            default: 35,
        },
        previewIcon: {
            type: String,
            default: "",
        },
        widthCustom: {
            type: String,
            default: "",
        },
        isBorder: {
            type: Boolean,
            default: false,
        },
        isFullWidth: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        /**
         * @property {Boolean} localOpen - условие отображение опций
         */
        return {
            searchValue: "",
            localOpen: this.open,
            scrollTop: 0,
            nodeCountVisible: 0,
            spacerHeight: 0,
        };
    },
    computed: {
        /**
         * Вернет title для селекта
         */
        title() {
            const currentOption =
                this.options.find((i) => i.id === this.value) || {};
            return currentOption.value || this.defaultTitle;
        },
        /**
         * Массив значений по ключу поиска
         */
        filteredOptions() {
            const filteredOptions = this.options.filter((item) => {
                const optionValue = String(item.value).toLowerCase();
                const searchValue = String(this.searchValue).toLowerCase();

                return optionValue.startsWith(searchValue);
            });
            return filteredOptions;
        },
        virtualOptions() {
            return this.isVirtualScroll
                ? this.filteredOptions.slice(
                      this.nodeIndexStart,
                      this.nodeIndexEnd + 1
                  )
                : this.filteredOptions;
        },
        optionsRequired() {
            const optionsRequired = this.options
                .filter((option) => {
                    const {
                        required = false,
                        requiredGroup = "",
                        requiredType = "",
                    } = option;
                    return (
                        Boolean(required) ||
                        String(requiredGroup) !== "" ||
                        String(requiredType) !== ""
                    );
                })
                .map((option) => {
                    const { id = "" } = option;
                    return String(id);
                });
            return optionsRequired;
        },
        nodeHeight() {
            const { nodeHeight = 35 } = this.virtualScrollConfig;
            return nodeHeight;
        },
        nodeIndexStart() {
            const nodeIndexStart =
                Math.floor(this.scrollTop / this.nodeHeight) - 5;
            return Math.max(0, nodeIndexStart);
        },
        nodeIndexEnd() {
            const nodeIndexEnd = this.nodeIndexStart + 12;
            return Math.min(nodeIndexEnd, this.filteredOptions.length - 1);
        },
        containerHeight() {
            return this.filteredOptions.length * this.nodeHeight;
        },
        spacerTopStyle() {
            const spacerTopStyle = `${this.nodeIndexStart * this.nodeHeight}px`;
            return spacerTopStyle;
        },
        spacerBottomStyle() {
            const spacerBottomStyle = `${
                (this.filteredOptions.length - 1 - this.nodeIndexEnd) *
                this.nodeHeight
            }px`;
            return spacerBottomStyle;
        },
    },
    watch: {
        localOpen(isLocalOpen) {
            if (this.isVirtualScroll) {
                if (isLocalOpen)
                    this.$nextTick(() => {
                        this.getNodeCountVisible();
                        this.getSpacerHeight();
                    });
                else this.scrollTop = 0;
            }
        },
        filteredOptions() {
            if (this.localOpen && this.isVirtualScroll) {
                this.$refs.selectOptionsContainer.scrollTop = 0;
                this.scrollTop = 0;
            }
        },
    },
    created() {
        // Сработает при эмите события cancel в родителе
        this.$parent.$on("$closeSelect", this.onClose);
    },
    methods: {
        openDropdown() {
            this.$refs?.BaseDropdown?.onOpen();
        },
        onCloseDropdown() {
            this.$emit('closeDropdown');
        },
        onRemoveChecked(option) {
            this.$emit('removeChecked', option)
        },

        onScroll(event) {
            if (this.isVirtualScroll) {
                const { currentTarget = {} } = event;
                const { scrollTop = 0 } = currentTarget;
                this.scrollTop = scrollTop;
            }
        },
        getIsRequired(option) {
            const { id = "" } = option;
            return this.optionsRequired.includes(String(id));
        },
        updateSearchValues(value) {
            this.searchValue = value;
        },
        getNodeCountVisible() {
            const { selectOptionsContainer = {} } = this.$refs;
            const bounding =
                selectOptionsContainer.getBoundingClientRect() || {};
            const { height = 0 } = bounding;
            const nodeCountVisible = Math.floor(height / this.nodeHeight);
            this.nodeCountVisible = nodeCountVisible;
        },
        getSpacerHeight() {
            this.spacerHeight = this.containerHeight;
        },
        getOptionStyle() {
            const optionStyle = {
                "white-space": "nowrap",
                height: `${this.nodeHeight}px`,
            };
            return this.isVirtualScroll ? optionStyle : {};
        },
        getSpacerStyle() {
            const spacerStyle = {
                "padding-top": this.spacerTopStyle,
                "padding-bottom": this.spacerBottomStyle,
            };
            return this.isVirtualScroll ? spacerStyle : {};
        },
        /**
         * @returns {Object} классы для опций
         */
        getOptionClasses(option) {
            if (Array.isArray(this.value)) {
                return {
                    "select-options__item_active": this.value.includes(
                        option.id
                    ),
                };
            }

            return {
                "select-options__item_active": this.value === option.id,
            };
        },
        /**
         * close
         */
        onClose() {
            this.$emit("$closeDropdown");
            this.$emit("close");
        },
        /**
         * @param {Object} option - option по которому был совершен клик
         */
        onCheck(option) {
            if (option.isSelected) return

            this.$emit("check", option);

            if (this.isCloseAfterCheck) {
                this.onClose();
            }
        },
        onDropdown(event) {
            this.localOpen = event;
        },
        getTooltip(value) {
            return value.length > this.maxLengthItem ? value : "";
        },

        slicedValue(value) {
            return value.length > this.maxLengthItem
                ? value.slice(0, this.maxLengthItem) + "..."
                : value;
        },
    },
};
</script>

<style lang="scss" scoped>
$primary-color: #f0f3f8 !default;
$success-color: #6ec87a !default;
$primary-disabled-color: #dce1e8 !default;
$color-gray-hover: #f2f2f2;
$color-green: #6ec87a;

.select {
    display: inline-block;
    position: relative;
    width: max-content;
    user-select: none;
    outline: none;
    font-size: 12px;

    &.isFullWidth {
        width: 100%;

        .select-preview {
            background-color: #f5f5f5;

            &:focus {
                background: #fff;
            }
        }
    }

    &-preview {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 10px;
        // border: 1px solid transparent;
        width: 100%;
        cursor: pointer;
        transition: 0.2s;
        color: #000;
        font-weight: 400;
        background-color: transparent;
        box-sizing: border-box;

        &__container-label {
            display: flex;
        }

        &_disabled {
            color: $assistant-color;
        }

        &:hover {
            background-color: $color-gray-hover;
        }

        &:focus {
            border: 1px solid $color-green;
            background-color: #fff;
            color: #000;
        }

        &__circle {
            border-radius: 100%;
            width: 14px;
            height: 14px;
            background: #6ec87a;
            margin-right: 5px;
            &-icon {
                padding-left: 2px;
            }
        }

        &__icon {
            font-size: 16px;
            color: #000;
            margin-right: 10px;
        }

        &__label {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            flex-grow: 0;
        }

        &__arrow {
            font-size: 8px;
            margin-left: 5px;
        }
    }

    &-dropdown {
        height: 100%;
        max-height: 350px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &__search {
            width: 100%;
            cursor: pointer;
            border-radius: inherit;
            transition: 0.1s;

            input {
                border: none;
                border-bottom: 1px solid $primary-disabled-color;
                background: transparent;
                height: 34px;
                width: 100%;
                display: block;
                outline: none;
                padding-left: 16px;

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath id='iconmonstr-magnifier-2' d='M10,9.019,7.394,6.434a4.012,4.012,0,0,0,.78-2.38A4.087,4.087,0,0,0,0,4.054,4.092,4.092,0,0,0,6.391,7.4L9.011,10,10,9.019ZM1.2,4.054A2.888,2.888,0,1,1,4.087,6.92,2.88,2.88,0,0,1,1.2,4.054Z' fill='%23b9bbc2'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center left;
                background-size: 10px;
                font-size: 12px;

                &::placeholder {
                    color: $primary-disabled-color !important;
                }
            }
        }

        &__header {
            flex-shrink: 0;
            flex-grow: 0;
        }
        &__top {
            margin-bottom: 4px;
        }
        &__footer {
            flex-shrink: 0;
            flex-grow: 0;
            margin-top: 20px;
        }
    }

    &-options {
        flex-grow: 1;
        overflow: auto;

        &__item {
            border: 1px solid transparent;
            max-width: 100%;
            cursor: pointer;
            // border-radius: 400px;
            border-radius: 4px;
            transition: 0.1s;
            // padding: 8px 15px;
            padding: 6px 10px;
            display: flex;

            &.isFullWidth {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &.selected:hover {
                background: #fff !important;
                cursor: auto;
            }

            &.selected .select-option__item-text {
                opacity: .5;
                pointer-events: none;
            }

            &.selected .select-options__item-close {
                cursor: pointer;
                pointer-events: all;
            }

            &_required {
                color: #f84967;
            }
            &:hover:not(.select-options__item_active) {
                background: $primary-color;
            }

            span {
                // white-space: nowrap;
                display: block;
                //width: 98%;
                //text-overflow: ellipsis;
                // white-space: nowrap;
                overflow: hidden;
            }

            &_active {
                // border: 1px solid $success-color;
                color: $success-color;
            }
        }
    }
}
</style>
