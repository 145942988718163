<template>
    <DatePicker
        v-model="dateStringProxy"
        :disabled="isDisabled"
        :clearable="isClearable"
        :language="ruLang"
        :format="'YYYY-MM-DD'"
    />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ruLang from 'vue2-datepicker/locale/ru';
export default {
    name: 'BaseDatepickerDay',
    components: { DatePicker },
    props: {
        dateString: {
            type: String,
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isClearable: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        ruLang: ruLang,
        date: null
    }),
    computed: {
        dateStringProxy: {
            get() {
                const dateStringToDatePicker = new Date(`${this.dateString}T00:00:00`);

                return dateStringToDatePicker;
            },
            set(dateStringValue = '') {
                if (!dateStringValue) {
                    this.$emit('update:dateString', '');
                } else {
                    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                    const dateStringFromDatePicker = dateStringValue.toLocaleDateString('en-CA', options);
                    
                    this.$emit('update:dateString', dateStringFromDatePicker);
                }
            }
        }
    }
}
</script>