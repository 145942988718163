import {mapState, mapGetters, mapMutations} from 'vuex';
export default {
    data: () => ({
        kitsFolderPosition: {},
        folderSize: '',
    }),
    computed: {
        /** Проксируем геттеры из стора */
        ...mapGetters('folders', ['folders']),
        ...mapState({
            isMobile: state => state.isMobile,
        }),
        /**
         * Активированные папки
         * @returns {Array}
         */
        kitsFolderIdsActive() {
            const kitsFolderIdsActive = this.folders.map(folder => {
                const {id = '-1'} = folder;
                return String(id);
            });
            return kitsFolderIdsActive;
        },

        isShare() {
            const {query = {}} = this.$route;
            const {shareToken = null} = query;
            return shareToken !== null;
        },
    },
    methods: {
        /** Проксируем мутациииз стора */
        ...mapMutations('folders', ['openFolder', 'closeFolder', 'bringToFront']),
        /**
         * Переключить отображение папки
         * @param {String} id - идентификатор набора
         * @param {String} type - тип набора
         */
        toggleFolder(id = '-1', type = '') {
            if (!this.kitsFolderIdsActive.includes(String(id))) {
                this.kitsFolderPosition = {
                    ...this.kitsFolderPosition,
                    [id]: {...this.calculatePositionFolder(type)}
                };
                this.openFolder(String(id));
            }
            else
                this.closeFolder(String(id));
        },
        /**
         * Вычислить позицию для таблицы открывающегося элемента набора
         * @param {String} type - тип элемента набора (found|custom)
         */
        calculatePositionFolder(type = '') {
            let position = {top: '0', left: '0'};
            if (type === 'found') {
                position = {
                    top: `${Math.ceil(window.innerHeight * 0.17)}px`,
                    left: '0'
                };
            }
            if (type === 'custom') {
                const kitsTableActiveCustom = this.kitsFolderIdsActive.filter(kitId => String(kitId) !== String(this.kitFoundId));
                const topDefault = this.isShare ? 46 : 134;
                position = {
                    top: `${topDefault + kitsTableActiveCustom.length * 10}px`,
                    left: this.isMobile ? '0' : `${0 + kitsTableActiveCustom.length * 10}px`,
                };
            }

            return position;
        },
        /**
         * Рассчитать ширину папки
         * @param {String} type - тип папки
         * @param {String} size - размер окна на весь экран, половина экрана, страндартный
         * @return {String}
         */
        calculateFolderWidth(type = '', size = '', isMobile = false) {
            let width = '100%';
            if (type === 'found' && size === '') {
                width = `${Math.ceil(window.innerWidth * 0.7)}px`;
            }
            if (type !== 'found' && size === '') {
                // width = `${Math.ceil(window.innerWidth * 0.5)}px`;
                isMobile ?  width = '100%' : width = '50%';
            }

            return width;
        },
        /**
         * Рассчитать высоту папки
         * @param {String} type - тип папки
         * @param {String} size - размер окна на весь экран, половина экрана, страндартный
         * @return {String}
         */
        calculateFolderHeight(type = '', size = '', isMobile = false) {
            let height = 'calc(100vh - 150px)';
            if (type === 'found' && size === '') {
                height = `${Math.ceil(window.innerHeight * 0.65)}px`;
            }
            if (type !== 'found' && size === '') {
                isMobile ? height = '100%' : height = '300px';
            }

            return height;
        },
        /**
         * Получить позицию для таблицы открывающегося элемента набора
         * @param {String} id - идентификатор элемента набора
         * @param {String} position - top или left позиция
         * @param {String} size - размер окна на весь экран, половина экрана, страндартный
         */
        getPositionFolderKit(id = -1, position = '', size = '', isMobile = false) {
            if (isMobile)
                return '0';
            else if(Object.keys(this.kitsFolderPosition).includes(String(id)) && size === '') {
                return this.kitsFolderPosition[id][position];
            }

            return '145px';
        },
    }
};