import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";
import axios from "axios";
import VTooltip from "v-tooltip";
import Clipboard from "v-clipboard";
import vClickOutside from "v-click-outside";
import DNotification from "@/plugins/DNotification";

import {
    pluginCap as Cap,
    pluginCapWithSidebar as CapWithSidebar,
} from "@oohdesk-kit/lince-cap";

import {
    pluginWidgetMenu as WidgetMenu,
    pluginWidgetMapPoi as WidgetMapPoi,
    pluginColorPanel as ColorPanel,
    pluginWidgetMapBaseLegend as WidgetMapBaseLegend,
} from "@oohdesk-kit/lince-widgets-map";

import LinceMapWrapper from "@oohdesk-kit/lince-map";
import LinceRealtimeSystem from "@oohdesk-kit/lince-realtime-system";
import LinceFilterLocation from "@oohdesk-kit/lince-filter-location";

import "./assets/scss/main.scss";

const options = {
    autoHide: false,
    defaultDelay: 0,
};

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.use(VTooltip, options);
Vue.use(Clipboard);
Vue.use(vClickOutside);
Vue.use(DNotification);
Vue.use(Cap);
Vue.use(CapWithSidebar);
Vue.use(LinceMapWrapper);
Vue.use(WidgetMenu);
Vue.use(WidgetMapPoi);
Vue.use(WidgetMapBaseLegend);
Vue.use(ColorPanel);
Vue.use(LinceRealtimeSystem);
Vue.use(LinceFilterLocation);

window.vueRouter = router;

new Vue({
    render: (h) => h(App),
    store,
    router,
}).$mount("#app");
