import ServiceApi from '../ServiceApi';
import ServiceFilter from '../ServicesFilter/ServiceFilter';
import UtilLocalStorage from "../../utils/UtilLocalStorage";

/** Сервис для работы с картой */
export default class ServiceMap {
    /**
     * Название микросервиса для запросов
     * @private
     * @static
     * @type {String}
     */
    static _microserviceName = 'apiMap';

    /**
     * Ключ для получения движка карты из локального хранилища
     * @private
     * @static
     * @type {String}
     */
    static _localStorageMapDriverKey = 'mapDriver';

    /**
     * Маршруты для запросов
     * @private
     * @static
     * @type {Object}
     * @property {String} searchMap - маршрут для поиска по карте
     * @property {String} getMarkers - маршрут для получения маркеров
     */
    static _requestRouts = {
        searchMap: '/main/search-address',
        getMarkers: '/main/map-markers',
        editDirectionMarker: '/main/change-direction'
    };

    /**
     * Ключи для обработки результата поиска по карте
     * @private
     * @static
     * @type {Map}
     */
    static _addressKeys = new Map([
        ['city_with_type', ['city_with_type']],
        ['street_with_type', ['street_with_type']],
        ['house', ['house_type', 'house']],
        ['block', ['block_type', 'block']],
        ['flat', ['flat_type', 'flat']]
    ]);

    /**
     * Есть ли движок карты в локальном хранилище
     * @private
     * @static
     * @returns {Boolean}
     */
    static get _isMapDriver() {
        return UtilLocalStorage.isData(this._localStorageMapDriverKey);
    }


    /**
     * Получить движок карты из локального хранилища
     * @public
     * @static
     * @returns {String}
     */
    static getMapDriver() {
        return this._isMapDriver ? UtilLocalStorage.getData(this._localStorageMapDriverKey) : 'google';
    }

    /**
     * Установить движок карты в локальное хранилище
     * @public
     * @static
     * @param {String} mapDriver - движок карты (google|yandex)
     */
    static setMapDriver(mapDriver = '') {
        UtilLocalStorage.setData(this._localStorageMapDriverKey, String(mapDriver));
    }

    /**
     * Поиск по карте
     * @public
     * @static
     * @async
     * @param {String} searchString - строка поиска
     * @param {Function} actionAfter - событие, сработающее после поиска
     */
    static async searchMap(searchString = '', actionAfter = () => {}) {
        const reqBody = this._searchMapBefore(String(searchString));
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.searchMap, reqBody);
            const {data: searchItems = []} = res;
            const searchItemsAfter = this._searchMapAfter(searchItems);
            actionAfter(searchItemsAfter);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить данные кнопок тепловых карт
     */
    static async getHeatmapList() {
        const reqBody = {
            filter_main: {
                main_params: { city_id: [], date: [], },
                price_group_id: [],
            },
            filter_table: { page: 1, },
        };

        try {
            const { data: heatmapList } = await ServiceApi.post("apiMap", "/heatmap/list", reqBody);

            return heatmapList
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Получить данные точек тепловых карт
     */
    static async getHeatmapData(heatmapId = '', filterSelectedData = {}) {
        const reqBody = { 
            heatmapId: String(heatmapId),
            filter_main: { main_params: { ...filterSelectedData } }
        };

        try {
            const { data: heatmapData } = await ServiceApi.post("apiMap", "/heatmap/points", reqBody);

            return heatmapData;
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для поиска по карте
     * @private
     * @static
     * @param {String} searchString - строка поиска
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.search - тело запроса для поиска
     * @returns {Object} reqBody.search.query - строка поиска
     */
    static _searchMapBefore(searchString = '') {
        const reqBody = {search: {query: String(searchString)}};
        return reqBody;
    }

    /**
     * Постобработка результата поиска по карте
     * @param {Array<Object>} searchItems - результат поиска по карте
     * @param {Object} searchItems[].data - json-описание результата поиска
     * @param {Object} searchItems[].data.city_with_type - город результата поиска
     * @param {Object} searchItems[].data.street_with_type - улица результата поиска
     * @param {Object} searchItems[].data.house_type - типа дома результата поиска
     * @param {Object} searchItems[].data.house - дом результата поиска
     * @param {Object} searchItems[].data.block_type - тип блока результата поиска
     * @param {Object} searchItems[].data.block - блок результата поиска
     * @param {Object} searchItems[].data.flat_type - тип квартиры результата поиска
     * @param {Object} searchItems[].data.flat - квартира результата поиска
     * @param {String} searchItems[].unrestricted_value - сырое значение результата поиска
     * @param {String} searchItems[].value - значение результата поиска по карте
     * @returns {Array<Object>} searchItemAfter - постобработанный результат поиска по карте
     * @returns {String} searchItemAfter[].id - id значения результат поиска по карте
     * @returns {String} searchItemAfter[].value - строка с адресом значения результат поиска по карте
     * @returns {String} searchItemAfter[].lat - широта адреса значения результат поиска по карте
     * @returns {String} searchItemAfter[].lng - долгота адреса значения результат поиска по карте
     */
    static _searchMapAfter(searchItems = []) {
        const searchItemsAfter = searchItems.map((searchItem, index) => {
            const {data: searchItemData = {}} = searchItem;
            const {
                geo_lat: lat = '',
                geo_lon: lng = ''
            } = searchItemData;

            const addressValue = Array.from(this._addressKeys.keys()).reduce((addressValue, addressKey) => {
                const itemKeys = this._addressKeys.get(addressKey);

                const itemValue = itemKeys.reduce((itemValue, itemKey) => {
                    const searchItemDataValue = searchItemData[itemKey] ?? '';
                    return itemValue === '' ? searchItemDataValue : `${itemValue} ${searchItemDataValue}`;
                }, '');
                const itemValueReduced = itemValue === '' ? addressValue : `${addressValue}, ${itemValue}`;

                return addressValue === ''  ? itemValue : itemValueReduced;
            }, '');

            const searchItemAfter = {
                id: String(index),
                value: addressValue,
                lat: String(lat),
                lng: String(lng)
            };

            return searchItemAfter;
        }).filter(searchItem => searchItem.value !== '');

        return searchItemsAfter;
    }

    /**
     * Получить маркеры набора
     * @public
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @param {Function} actionAfter - событие, сработающее после получения маркеров
     */
    static async getMapMarkers(kitParams = {}, filterTable = {}, actionAfter = () => {}) {
        await this._getMarkers(kitParams, 0, filterTable, actionAfter);
    }

    /**
     * Сделать запрос на получение маркеров
     * @private
     * @static
     * @async
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @param {Number} page - номер страницы для загрузки
     * @param {Function} actionAfter - событие, сработающее после получения маркеров
     */
    static async _getMarkers(kitParams = {}, page = 0, filterTable = {}, actionAfter = () => {}) {
        const reqBody = this._getMarkersBefore(kitParams, page, filterTable);
        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getMarkers, reqBody);
            const {data = {}} = res;
            const {count: pageCount = 0, items: markers = []} = data;

            const markersAfter = this._getMarkersAfter(kitParams, markers);
            actionAfter(markersAfter, page >= (pageCount - 1));

            page += 1;
            if (page < pageCount)
                this._getMarkers(kitParams, page, filterTable = {}, actionAfter);
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Подготовить тело запроса для получения маркеров
     * @private
     * @static
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @param {Number} page - номер страницы для загрузки
     * @returns {Object} reqBody - тело запроса
     * @returns {Object} reqBody.filter_main - тело фильтра
     * @returns {Object} reqBody.filter_main.main_params - выбранные параметры фильтра
     * @returns {Array<Number|String>} reqBody.filter_main.main_params.city_id - выбранные идентификаторы городов фильтра
     * @returns {Array<String>} reqBody.filter_main.main_params.date - выбранные даты фильтра
     * @returns {Object} reqBody.filter_table - тело фильтра таблицы
     * @returns {Object} reqBody.filter_table.page - номер страницы для загрузки
     */
    static _getMarkersBefore(kitParams = {}, page = 0, filterTable = {}) {
        const {kitItemId = ''} = kitParams;
        const filterSelected = ServiceFilter.getFilterSelected();
        const reqBody = {
            filter_main: {
                main_params: {...filterSelected},
                price_group_id: String(kitItemId)
            },
            filter_table: {
                page: parseInt(page),
                filter: {...filterTable},
            }
        };

        return reqBody;
    }

    /**
     * Постобработка результата запроса на получения маркеров
     * @private
     * @static
     * @param {Object} kitParams - параметры набора
     * @param {String} kitParams.kitItemId - id набора
     * @param {String} kitParams.kitItemColor - цвет набора
     * @param {Array<Object>} markers - массив маркеров
     * @param {Number} markers[].id - id маркера
     * @param {Number} markers[].lat - широта маркера
     * @param {Number} markers[].lng - долгота маркера
     * @param {Object} markers[].colors - цвет маркера по статусам
     * @param {NUmber} markers[].direction - направления
     * @param {Object} markers[].lighted - освещение у маркера
     * @param {Object} markers[].format_id - id форматов сторон
     * @param {Object} markers[].kind_id - id видов сторон
     * @param {Object} markers[].supp_id - id подрядчики сторон
     * @param {Object} markers[].type_id - id типы сторон
     * @param {Array<Object>} markers[].sides - стороны маркера
     * @param {String} markers[].sides.code - код стороны маркера
     * @param {Number} markers[].sides.id - id стороны маркера
     * @param {Array<Object>} markers[].sides.prices - цены стороны маркера
     * @param {String} markers[].sides.prices[].date - дата для цены
     * @param {Number} markers[].sides.prices.id - id цены
     * @param {String} markers[].sides.prices.status - статус цены
     * @param {String} markers[].sides.prices.user_status - пользовательский статус цены
     * @returns {Array<Object>} markersAfter - постобработанный массив маркеров
     * @returns {Object} markersAfter[].extraData - доп. данные для маркера
     * @returns {String} markersAfter[].extraData.kitItemId - id набора
     * @returns {String} markersAfter[].fill_color - цвет заливки маркера
     */
    static _getMarkersAfter(kitParams = {}, markers = []) {
        const {kitItemId = '', kitItemColor = '', kitItemType = '', kitItemTypeKey = '', kitItemFigure = 'circle'} = kitParams;
        const markersAfter = markers.map(marker => {
            const {sides = []} = marker;
            const sidesOpn = sides.map(side => String(side?.opn_number ?? '')).filter(sideOpn => sideOpn !== '');
            const opnNumber = sidesOpn[0] ?? '';
            const sidesCodes = sides.map(side => String(side?.code));
            const sidesColors = sidesCodes.reduce((sidesColors, sideCode) => {
                return {
                    ...sidesColors,
                    [sideCode]: String(kitItemColor)
                };
            }, {});
            const markerAfter = {
                ...marker,
                additionalId: String(kitItemId),
                figure: kitItemFigure,
                clusterType: 'default',
                extraData: {
                    type: "default",
                    kitItemId: String(kitItemId),
                    kitItemType,
                    kitItemTypeKey,
                    ...marker
                },
                fill_color: String(kitItemColor),
                sidesColors,
                opnNumber
            };

            return markerAfter;
        });

        return markersAfter;
    }

    static async editDirectionMarker(markerId = '', direction = 0, actionAfter = () => {}) {
        await this._editDirectionMarker(markerId, direction, actionAfter);
    }

    static async _editDirectionMarker(markerId = '', direction = 0, actionAfter = () => {}) {
        const reqBody = this._editDirectionMarkerBefore(markerId, direction);
        try {
            await ServiceApi.post(this._microserviceName, this._requestRouts.editDirectionMarker, reqBody);
            actionAfter();
        } catch (error) {
            console.log(error);
        }
    }

    static _editDirectionMarkerBefore(markerId = '', direction = 0) {
        const reqBody = {
            id: String(markerId),
            direction: parseInt(direction)
        };

        return reqBody;
    }
}
